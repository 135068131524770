/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { ResourcesProps } from "../../includes/types";
import { CheckIcon, CloseIcon } from "../../assets/icons";
import { blueColor } from "../../includes/constants";

const ImagePreview = ({imageList,onClose}:{imageList:ResourcesProps[];onClose:()=>void})=>{
const [selectedItem,setSelectedItem] = useState("");
const [selectedIndex,setSelectedIndex] = useState<number>(0);
useEffect(()=>{
 if(imageList.length !== 0)
 {
    setSelectedItem(imageList[0].uri!) 
 }
},[imageList])
if(selectedItem === "")
{
    return null;
}
const handleNex = ()=>{
    const index =  selectedIndex + 1 ;
    const item  = imageList.find((a,i)=>i === index)
    if(item)
    {
      setSelectedItem(item.uri!)
      setSelectedIndex(index); 
    }
}
const handlePrev = ()=>{
    const index =  selectedIndex - 1 ;
    const item  = imageList.find((a,i)=>i === index)
    if(item)
    {
      setSelectedItem(item.uri!)
      setSelectedIndex(index); 
    }
}
return <div className="modal" >
    <div className="row" >
    <div className="col-1" ></div>    
    <div className="col-10 " >
    <div className="card imagePreviewContainer mt-5 mb-5" >
    <div className="imagePreviewContainerInner">
     <img  
     src={selectedItem} 
     />
     <button 
     onClick={handleNex}
     className="carousel-control-next" 
     type="button" 
     style={{right:40}}
     >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
    </button>
    <button 
    onClick={handlePrev}
    style={{left:40}}
    className="carousel-control-prev" type="button" >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
    </button>
     <div className="imagePreviewThumnailContainer" >
    {imageList.map((a,i)=><div className="divWp" >
     <img 
    onClick={()=>{
        setSelectedIndex(i);
        setSelectedItem(String(a?.uri))
    }}
    style={{objectFit:"fill"}}
    src={a.uri} />
    
    {selectedItem === a.uri && <span className="check" >
        <CheckIcon />
    </span>}
    </div>)}
    </div>
    </div>
    <span className="close"
    onClick={onClose}
    >
        <CloseIcon color={blueColor} />
    </span>
    </div>    
    </div>    
    <div className="col-1" ></div>    
    </div>
</div>
}
export default ImagePreview;