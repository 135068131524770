/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext,RefObject, useEffect, useRef, useState, FormEvent } from 'react'
import './style.css';
import compareIcon from './icons/compare.svg';
import { ActionProps, AgentDetailProps, CommentProps, DevelopersProps, ItemProps, ResourcesProps, UserDataProps } from '../../includes/types';

import mapPin from '../../assets/icons/map-pin.svg'
import { NavLink, Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AddressIcon, EditIcon, EmailIcon, HomeIcon, LandIcon, MobileIcon } from '../../components/icons';
import { AddListingIcon } from '../../images';
import { CheckIfUserLoggedin, GetData, MarkEmail, MarkMobileNumber, PostData, SendToast } from '../../includes/function';
import { CheckIcon, CloseIcon, ReturnIcons, TrashIcon, VideoIcon, VirtualIcon } from '../../assets/icons';
import { PlaceHolderAvatar, RELOAD, ROUTES, blueColor } from '../../includes/constants';
import { AppContextModel } from '../../components/appContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as y from 'yup';
import Moment from 'moment';
import { BaseLoader } from '../../css/spinnner';
import { SubmitButton, TextAreaField } from '../../components/forms/inputField';
import PaymentView from '../../components/payment';
import HeartIcon from './icons/heart';
import PrinterIcon from './icons/print';
import Pagination from '../../components/pagination';
import moment from 'moment';
import { ReportView } from '../../components/report';
import { CommentList } from '../developers/details';
import { loginBtnRef } from '../../App';
import { VideoTourFrame } from '../../components/videoTour';
import { VirtualTourFrame } from '../../components/virtualTour';
import { toast } from 'react-toastify';
import { SelectField } from '../../components/forms/inputField/select';
import { ImageProps } from '../../components/newlisting/slider';
import MapHandlerComponent from '../../includes/mapHandler';
import MoreImagesPreview from './moreImagePreview';
import ImagePreview from '../../components/imagePreview';
export interface ImgProps {
  uri:string;
  name:string;
  type:string;
}
const ImageGallery = () => {
  const [preview,setPreview] = useState<ResourcesProps | null>(null)
  const [loading,setLoading] = useState<boolean>(false);
  const [showVideoModal,setShowVideoModal] = useState<boolean>(false);
  const [showVirtualModal,setShowVirtualModal] = useState<boolean>(false);
  const [liked,setLike] = useState<boolean>(false);
  const [loadingLike,setLoadingLike] = useState<boolean>(false);
  const [selectedIndex,setSelectedIndex] = useState<number>(0);
  const [details,setDetails] = useState<ItemProps>({})
  const [thumbnail,setThumbnail] = useState<string>("")
  const [list,setList] = useState<ResourcesProps[]>([])
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const pCon = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const {id} = useParams();
  const GetDetails = ()=>{
    setLoading(true);
    GetData("house_details",{
      id:id
    },false).then((res)=>{
      setLoading(false);
    if(res.status)
    {
      console.log(res.data)
      setThumbnail(res.data.thumbnail)
      setDetails(res.data)
      setLike(res.data.liked)
      setList([{uri:res.data.thumbnail},...res.data.images]);
    }else{
      toast.error(res.message,{
        position:"top-center"
      })
      navigate(ROUTES.HousesScreen)
    }
  })
  }

  const handleWishlist = ()=>{
    setLoadingLike(true);
    PostData("save_to_wishlist",{
      propertyId:id
    }).then((res)=>{
      setLoadingLike(false);
      setLike(!liked);
    })
  }

  useEffect(()=>{
    GetDetails();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])

  useEffect(()=>{
    var index = 0;
    const x = setInterval(()=>{
      if(details.images && details.images?.length > 1)
        {
    const all:string[] = [details.thumbnail, ...details.images?.map((a, i) => a.uri)].filter((a, i) => a !== undefined) as string[]
      if(index >= all.length - 1)
      {
        index = 0;
      }else{
       if(all.length !== 0 )
      {
        index += 1;
      }
      }
      setSelectedIndex(index)
      const found = all.find((a,i)=>i === index);
      if(found)
      {
      setThumbnail(found)
      }
    }else{
      clearInterval(x);
    }
    },3000)
    return ()=>{
      clearInterval(x);
    }
  },[thumbnail])
if(!pathname || String(pathname).split("/").length !== 3)
{
  return <Navigate to="/" />
}
if(loading)
{
  return <div className='p-5'>
    <span className='small px-2' >Fetching details...</span>
    <BaseLoader />
  </div>
}

  return (<div >
  <div className='imageGalleryContainer'
    ref={pCon}
  >
     <img 
    src={thumbnail}
    style={{width:"100%",height:"100%",objectFit:"cover"}}
    /> 
   <MoreImagesPreview 
   selectedItem={selectedIndex}
   list={list}
   onSelect={(image)=>{
    setPreview(image)
   }}
   onPreview={()=>{
    setPreview(list[0])
   }}
   />
    <div className='imageGalleryBlind p-5 item-details' >
    <div className='row'>
  <div className='col-9'>
 <div className='py-3'>
  <span className='whiteBtnSm'>
    {String(details?.type).toUpperCase()}
  </span>
  <span className='redBtnSm'>
    {String(details?.purpose).toUpperCase()}
  </span>
  {details?.featured && <span className='blueBtnSm'>
    FEATURED
  </span>}
  {details?.hot && <span className='redBtnSm'>
    HOT
  </span>}
  {!details?.trendy && <span className='yellowBtnSm'>
    TRENDY
  </span>}
</div>
<div  className={"white-text"}>
<h2>
  <span >{details?.title}</span> 
</h2>
</div>
<div 
className='address white-text' >
<img src={mapPin} 
style={{width:18}}
/> 
{details?.state}, {details?.country} <span className='priceDetails' >
{details?.currency}{details?.price}
</span>
</div>
</div>
<div className='col-3 d-flex justify-content-end align-items-center pt-5'>
<div  
className={'iconsContainer position-relative justify-end'} 
onClick={handleWishlist}
> 
{loadingLike?<BaseLoader 
color={blueColor}
size='small'
/>:<HeartIcon 
color={liked?"red":"white"}
size={22}
/>}
<div className='tips'>Like</div>
</div>
{/* <div  
className='iconsContainer position-relative' >
<PrinterIcon
size={25}
/>
<div className='tips'>Print</div>
</div> */}
{details.virtualTour?<div  
className='iconsContainer position-relative' 
onClick={()=>setShowVirtualModal(true)}
>
<VirtualIcon
size={25}
/>
<div className='tips'>Virtual Tour</div>
</div>:null}
{details.youtubeLink?<div  
className='iconsContainer position-relative'
onClick={()=>setShowVideoModal(true)}
>
<VideoIcon 
color='white'
size={25}
/>
<div className='tips'>Video Tour</div>
</div>:null}
<ReportView
onValue={()=>{
return ;
}}
postId={details.id!}
postType={details.type!}
/>
</div>
   </div>
    </div>
    </div>
    <div className='p-5'>
    <div className='row'>
    <div className='col-lg-8'>
  <div >
    <h5>Overview</h5> 
    <div className='pb-3'>
{details?.amenities?.filter((a,i)=>i < 5)?.map((a,i)=>{
  return <span className='amenitiesIconsLarge lightBlue' key={i} >
  <div className='d-flex justify-content-center align-item-center' >
  <img
  src={a.icon} 
  style={{width:25}}
  />
  <span className='ps-2 text-uppercase fs-7'>{a.name}</span>
  </div>
  </span>})}
 </div>
    <div className='pb-3'>
    <h5>Description</h5>
    <p>
    {details?.description}
    </p>
  </div>
  <div className='pb-3'>
    <h5>Amenites</h5>
    <div className='row' >
    {details?.amenities && details?.amenities.map((a,i)=><NavLink key={i} to={`/search?q=${String(a.name).toLowerCase()}&type=${String(details.type).toLowerCase()}`} className='col-3' ><div >
      <span className='p-2 fs-7 fw-bold' style={{color:blueColor}}>
      <img
  src={a.icon} 
  style={{width:20}}
  />
  <span className='ps-3'>{String(a.name).toUpperCase()}</span>
   </span></div></NavLink>)}
  </div>
  </div>
  </div>
  <div className='pb-3'>
    <h5>Location on Map</h5>
    <div className='mapContainer' style={{height:300}}  >
     <MapHandlerComponent 
     coordinates={[
      {lat:parseFloat(String(details?.latitude)),lng:parseFloat(String(details?.latitude))}
      ]}
      zoom={5}
      />
     
    </div>
  </div>
  <div className='pb-3'>
   <CommentButton 
    id={String(details?.id)}
    postType='house'
    />
  </div>
  </div>
  <div 
  className='col-lg-4 tourSection' 
  >
  <BookATour
  agentObject={details.agentObject!}
  propertyId={String(details?.id)}
  />
  </div>
  </div>
  </div>
  {showVideoModal && <VideoTourFrame 
  id={String(details?.id)}
  onClose={()=>setShowVideoModal(false)}
  uri={String(details?.youtubeLink)}
  type={String(details?.type)}
  />}
   {showVirtualModal && <VirtualTourFrame
  id={String(details?.id)}
  onClose={()=>setShowVirtualModal(false)}
  type={String(details?.type)}
  />}
  {preview && <ImagePreview 
  imageList={list}
  onClose={()=>{
    setPreview(null)
  }}
  />}
  </div>
  )
}

export default ImageGallery;

interface FormDataProps {
  selectedDate:string;
  selectedTime:string;
  message:string;
  sms:boolean;
  agree:boolean;
  email?:string;
  mobile?:string;
  propertyId?:string;
}
export const BookATour = ({agentObject,propertyId,profile}:{propertyId?:string;profile?:boolean;agentObject:UserDataProps})=>{
  const navigate = useNavigate();
   const [loading, setLoading] = useState<boolean>(false);
   const [formdata, setFormData] = useState<FormDataProps>({
    selectedDate:moment().toISOString(),
    message:"",
    selectedTime:"10:00 am",
    sms:false,
    agree:false,
    email:"",
    mobile:""
   });
  
   const SendMessage = (values:FormDataProps)=>{
    if(values.selectedTime === "")
    {
      return SendToast("Time is required",true);
    }
    setLoading(true);
    const data:FormDataProps = {
      ...values,
      propertyId,
      selectedDate:moment(values.selectedDate).format("DD-MM-YYYY")
    }
    PostData("schedule_a_tour",data,true).then((res)=>{
    localStorage.removeItem(`tour${propertyId}`);
    setLoading(false);
    handleForm({
      agree:false,
      message:"",
      selectedDate:moment().toISOString(),
      selectedTime:"10:00 am",
      sms:false
    })
    })
   }
const [paymentAmount,setPaymentAmount] = useState<string>("")
const handleForm = (data:FormDataProps)=>{
  setFormData(data)
  localStorage.setItem(`tour${propertyId}`,JSON.stringify(data))
}
   useEffect(()=>{
    const saveData:string | null = localStorage.getItem(`tour${propertyId}`);
    if(saveData !== null)
    {
      const data = JSON.parse(saveData);
      setFormData(data);
    }
 },[])
   return <>
    <AgentInfo 
    {...agentObject}
    />
   <div className='row'>
    <div className='agentTitle'>Schedule an inspection</div>
    <div className='tourContainer p-3'>
    <form
    onSubmit={(e:FormEvent)=>{
      e.preventDefault();
      const user = CheckIfUserLoggedin()
      if(user.token)
      {
        // if(localStorage.getItem(`tour${propertyId}`))
        // {
        //   localStorage.removeItem(`tour${propertyId}`);
        // }
        // formdata.selectedDate = moment(formdata.selectedDate).format("DD-MM-YYYY")
        SendMessage(formdata);
    }else{
      localStorage.setItem(`tour${propertyId}`,JSON.stringify(formdata));
      SendToast("You must login to able to send message",true);
      setTimeout(()=>{
      navigate(ROUTES.Login)
      },1500)
    }
    }}
    >
    <>
    <div className='mb-2'>
    <label><small>Date:</small></label>
    <div >
    <DatePicker 
    required
    onChange={(d)=>{
    handleForm({
      ...formdata,
      selectedDate:d!.toISOString()
    })
 
    }}
    selected={new Date(formdata.selectedDate)}
    minDate={new Date(Moment().add("d",1).toISOString())}
    maxDate={new Date(Moment().add("d",28).toISOString())}
    />
    </div>
    <div >
    </div>   
    </div>   
    <div className='mb-3'>
    <label>
        <small>Time:</small>
    </label>
    <SelectField
    required
    name='selectedTime'
    placeholder='Select time'
    onValueChange={(d)=>{
      handleForm({
        ...formdata,
        selectedTime:d.value!
    })
    }}
    value={formdata.selectedTime}
    options={[
      {name:"10:00 am",value:"10:00 am"},
      {name:"11:00 am",value:"11:00 am"},
      {name:"12:00 pm",value:"12:00 pm"},
      {name:"01:00 pm",value:"01:00 pm"},
      {name:"02:00 pm",value:"02:00 pm"},
      {name:"03:00 pm",value:"03:00 pm"},
      {name:"04:00 pm",value:"04:00 pm"},
      {name:"05:00 pm",value:"05:00 pm"},
      {name:"06:00 pm",value:"06:00 pm"}
    ]}
    />
    </div>
    <div className='mb-3' style={{position:"relative"}}>
    <label>
        <small>Message:</small>
    </label>
    <textarea 
    required
    maxLength={120}
    rows={4} 
    className='form-control roundTextarea text-break mb-3'
    placeholder='Hello, I am interested in'
    onChange={(d)=>{
      handleForm({
        ...formdata,
        message:d.target.value
    })
    }}
    value={formdata.message}
    />
    <small style={{fontSize:"0.7rem",position: "absolute",
    right: 10,
    bottom: -18}} className='text-positive'>{formdata.message.length}/120</small>
    </div> 
    <div >
  <div className="form-check" style={{display: "inline-flex"}}>
  <input className="form-check-input"
  onChange={(d)=>{
    handleForm({
      ...formdata,
      sms:!formdata.sms
  })
  }}
  type="checkbox" />
  <label className="form-check-label"  >
  Send via sms - <small>(This will attract the sum of N500 Per SMS)</small>
  </label>
  </div>
  <div className="form-check" style={{display: "inline-flex"}}>
  <input 
  className="form-check-input" 
  checked={formdata.agree}
  type="checkbox" 
  onChange={({target})=>{
    const user = CheckIfUserLoggedin();
    if(user.token)
    {
      handleForm({
        ...formdata,
        agree:!formdata.agree,
        email:user.userData.email,
        mobile:user.userData.mobile
      })
    }else{
      handleForm({
        ...formdata,
        agree:!formdata.agree,
      })
    }
  }}
  required
   />
  <label className="form-check-label mb-3"  >
  I consent to having this website store my submitted information so they can respond to my inquiry.
  </label>
  </div>
    </div>
    <div >
      <SubmitButton
      loading={loading}
      title='Submit'
       />
      </div>  
   {paymentAmount && <PaymentView 
    amount={paymentAmount}
    type='tour'
    onClose={()=>{
      setPaymentAmount("")
    }}
    onValue={(data)=>{
      // alert(JSON.stringify(data));
    }}
    memo='Payment for sms charge of N500'
    />}
      </>
   </form>
    </div>
    </div>
    </>
}

export const AgentInfo = (props:UserDataProps)=>{
 const [details,setDetails] = useState<UserDataProps | null>(null)
 const [other,setOtherDetails] = useState<{userObject:UserDataProps,list:AgentDetailProps[]}>({
  userObject:{},
  list:[{title:"No. of houses listed",value:"0"},
  {title:"No. of land listed",value:"0"}
 ]})
 const GetHostDetails = ()=>{
  PostData("host_details",{
    hostEmail:props?.email
  },false).then((res)=>{
    if(res.status)
    {
      setOtherDetails(res.data);
    }
  })
 }
  useEffect(()=>{
  if(props.email)
  {
    setDetails(props);
  }
  },[props])
  useEffect(()=>{
    GetHostDetails();
  },[])
 if(!details)
  {
    return <></>
  }

  return <div >
    <div className='row'>
    <div className='col-3'>
      <img 
      className='agentAvatar'
      src={details?.avatar?details?.avatar:PlaceHolderAvatar}
      />
    </div>
    <div className='col-7 p-4 pt-2' >
      <div className='agentTitle'>Agent</div>
      <div className='agentName'>{details.firstName} {details.lastName}</div>
      {details?.user_verified?<div className='verified mt-2'>
          <CheckIcon />
        <span className='verifiedText'>Verified</span>
      </div>:
      <div className='UnVerified mt-2'>
        <CloseIcon
        color='red'
         />
        <span className='UnVerifiedText'>Unverified</span>
      </div>}
    </div>
    </div>
    <table 
    className='table table-responsive table-bordered mb-5'
    >
     <tr  >
        <td className='t1 p-3'>Mobile:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
          <MobileIcon
          size={20}
          />
          </span>{details.user_verified?<NavLink to={`tel:${details.mobile}`} >{details.mobile}</NavLink>:MarkMobileNumber(String(details?.mobile))}
        </td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Email:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
         <EmailIcon
          size={20}
          />
          </span>{details.user_verified?<NavLink to={`mailto:${details.email}`} >{details.email}</NavLink>:MarkEmail(String(details.email))}</td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Office Address:</td>
        <td className='t2 p-3 ps-0'><span className='p-2'>
          <AddressIcon
          size={23}
          />
          </span>{details.user_verified?<a target='_blank' href={`https://map.google.com?q=${details.user_bussiness_address}`} >{details.user_bussiness_address}</a>:"not available."}</td>
      </tr>
    {other.list.map((a,i)=><tr key={i} >
        <td className='t1 p-3'>{a.title}:</td>
        <td className='t2 p-3 ps-0'><span className='p-2'>
          {a.title.includes("houses")?<HomeIcon
          size={23}
          color={blueColor}
          />:a.title.includes("land")?<LandIcon
          size={23}
          color={blueColor}
          />:null}
          </span>{a.value}</td>
      </tr>)}
      
    </table>

  </div>
}


export const CommentButton = (props:{id:string;postType:"developer"|"house"|"land"})=>{
  const [count,setCount] = useState<number>(0);
  const [comment,setComment] = useState<string>("");
  const [saveObj,setSaveObj] = useState<any>({});
  const [loading,setLoading] = useState<boolean>(false);
  const [showModal,setShowModal] = useState<boolean>(false);
  const thisDiv = useRef() as RefObject<HTMLDivElement>
  const [list,setList] = useState<CommentProps[]>([]);
  const GetComments = ()=>{
    if(!props.postType)
    {
      return ;
    }
    GetData(String(props.postType).toLowerCase() === "house" || String(props.postType).toLowerCase() === "land" ?"get_house_comments":"get_developer_comments",{
      id:props.id,
      page:count,
    },false).then((res)=>{
      if(res.status)
      {
        setCount(count + 1)
      setList(res.data);
      }
    })
   }
   useEffect(()=>{
    GetComments()
   },[])
 if(localStorage.getItem("userData") === null)
 {
   return <></>
 }
 return <>
 <div className='row my-3'>
 <div className='col-6 fs-5'>
  Comments
 </div>
 <div className='col-6 d-flex align-items justify-content-end'>
  <button 
  onClick={()=>{
    setShowModal(true);
  }}
  className='blueBtn p-2'>
  <span className='px-2'>Post Comment</span><EditIcon size={15} /> </button>
  </div>
  </div>
  <CommentList
  list={list}
  id={String(props.id)}
  type='House'
  reload={()=>{
    GetComments()
  }}
   />
  {showModal && <div 
  ref={thisDiv}
  className="modal" 
  tabIndex={-1} >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post a comment</h5>
            <button
              onClick={() => {
                setComment("");
                setShowModal(false);
              }}
              type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body"
      style={{overflow:"hidden"}}
      >
<form
onSubmit={(e:FormEvent)=>{
  e.preventDefault();
  if(!localStorage.getItem("token"))
  {
    const v:ActionProps[] = [];
    v[0] = {action:"comment",page:window.location.pathname,content:comment,redirect:"developer-details"}
    localStorage.setItem("action",JSON.stringify(v));
    window.location.href = ROUTES.Login+"?redirect="+v[0].redirect;
    return ;
  }
    setLoading(true);
    PostData("post_comment",{
      comment,
      propertyId:props.id,
    }).then((res)=>{
        setLoading(false); 
        if(res.status)
        {
        setCount(1)
        setTimeout(()=>{
          GetComments()
        },500)
        setComment("");
        setShowModal(false)
        }
    })
}}
>
<div >
 <TextAreaField
  name="comment"
  label="Comment" 
  max={100}
  required
  value={comment}
  onValueChange={({name,value})=>{
  setComment(value);
  }}

 />
 <SubmitButton
 loading={loading}
 title="Submit"
 type='submit'
/>
</div>
</form>
  </div>
        </div>
      </div>
    </div>}
  </>
}
