/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { PostData } from '../../includes/function';
import { BaseLoader } from '../../css/spinnner';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../includes/constants';

const EmailVerificationScreen = (prop:any)=> {
  const [loading,setLoading] = useState(false);
  const { token } = useParams();
  const location = useNavigate();
  const VerifyDetails = (token:string)=>{
    if(!token)
    {
      return ;
    }
    setLoading(true)
    PostData("email_verification",{
      code:token
    },true).then((res)=>{
      setLoading(false);
      if(res.status)
      {
        location(ROUTES.Login);
      }
    })
 }

  useEffect(()=>{
 if(token)
 {
 VerifyDetails(token)
 }
    },[token])
  if(loading)
  {
    return <div >
      <BaseLoader />
    </div>
  }
  
  return (<main className='content' style={{minHeight:600}} >
  <div className='row p-5' >

  </div>
</main>
)
}
export default EmailVerificationScreen;