/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, RefObject, useEffect, useRef, useState} from "react";
import {Wrapper, Status} from '@googlemaps/react-wrapper';
import { ListOfLatlng } from "./types";
import { MapPinIcon } from "../assets/icons";
import { BaseLoader } from "../css/spinnner";
interface MapProps {
  coordinates:ListOfLatlng[];
  zoom?:number;
}

const MapHandlerComponent = (props:MapProps)=>{
const [zoom,setZoom] = useState<number>(5)

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <div> </div>;
  return <BaseLoader size="sm" />;
};
if(props.coordinates.length === 0)
{
return <div style={{height:"100%",backgroundColor:"#70d5e9"}}>
  <img 
src="images/map.png"
style={{height:"100%",width:"100%",objectFit:"cover"}}
  />
</div>
}
return <Wrapper 
apiKey={'AIzaSyDHlvqtpYvHYYqE-EnifXQGn8KXu8Bc31Y'} 
render={render}

>
<MyMapComponent 
center={props.coordinates.length === 0?{lat:0,lng:0}:{lat:parseFloat(String(props.coordinates[0].lat)),lng:parseFloat(String(props.coordinates[0].lng))}}
zoom={zoom}
cordinates={props.coordinates}
/>
</Wrapper>
}
export default MapHandlerComponent;

function MyMapComponent({
  center,
  zoom,
  cordinates
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  cordinates?:ListOfLatlng[];
}) {
  const ref = useRef() as RefObject<HTMLDivElement>;
  const [loaded,setLoaded] = useState(false);
  const [saveList,setSaveList] = useState<ListOfLatlng[]>([]);
  useEffect(() => {
   
    const image =
    "images/map-marker.svg";
    const map = new window.google.maps.Map(ref.current!, {
      center,
      zoom,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      mapTypeControl: false,
      fullscreenControl:false
    });
    map.addListener("google-map-ready",()=>{
      setLoaded(true)
    })
    map.addListener("tilesloaded",()=>{
      setLoaded(true)
    })
var infowindow = new google.maps.InfoWindow();
var marker;
 cordinates?.forEach((cordinate,i)=>{
  marker = new google.maps.Marker({
    position: new google.maps.LatLng(cordinate.lat,cordinate.lng),
    map: map,
    icon: image,
  });
  google.maps.event.addListener(marker, 'click', (function(marker, i) {
    return function() {
      infowindow.setContent(Object.values(cordinate).join(","));
      infowindow.open(map, marker);
    }
  })(marker,i));
}) 
  },[]);
  return <div ref={ref} id="map" />;
}
