/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/first */
import React, { FormEvent, memo, useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom';
import { AccountTypes, ROUTES, RegExps } from '../../includes/constants';
import './style.css';
import {InputField, RadioButtonSingle, SubmitButton } from '../../components/forms/inputField';
import { PostData, ShowMessage } from '../../includes/function';
import MobileNumberUpdateModal from '../../components/mobileNumberUpdate';
import { UserDataProps } from '../../includes/types';
const SignUpScreen =()=> {
const [showPhoneVerication,setShowPhoneVerication] = useState<boolean>(false);
const [loading,setLoading] = useState(false);
const [inputData,setInputData] = useState<UserDataProps>({
  firstName:"",
  lastName:"",
  email:"",
  password:"",
  confirmPassword:"",
  mobile:"",
  dialCode:"234",
  flag:"",
  country:"",
  accountType:"Customer"
});
const RegisterUser = (values:UserDataProps)=>{
  if(values.password !== values.confirmPassword)
  {
    return ShowMessage("Password does not match.").error()
  }
  setLoading(true);
  PostData("register",{
    firstName:values.firstName,
    lastName:values.lastName,
    email:values.email,
    password:values.confirmPassword,
    mobile:values.mobile,
    dialCode:values.dialCode,
    country:values.country,
    accountType:values.accountType
  }).then((res)=>{
    setLoading(false)
    if(res.status)
    {
      setTimeout(()=>{
      window.location.href = ROUTES.Login;
    },1500)
    }
  })
}
const handleChange = ({name,value}:{name:string;value:string})=>{
  setInputData({
    ...inputData,
    [name]:value
  })
}
useEffect(()=>{
  window.scrollTo({behavior:"smooth",top:0})
},[])

return <div >
  <form
  action='post'
onSubmit={(e:FormEvent)=>{
  e.preventDefault();
  if(inputData.mobile === "")
  {
   setShowPhoneVerication(true)
  }else{
  RegisterUser(inputData);
  }
}}
>
<section  className="p-5 py-2 blueSection">
<div className='container d-flex align-items-center justify-content-center ' >
<div className='row p-5 mt-5' >
<div className='col-lg-3 col-md-1'></div>
<div className='col-lg-6 col-md-10 card rounded-5'>
<div className='row p-3' >
<h4>Sign-Up</h4>
<div className='row' >
<div className="col-12" >
<div className="mb-3">
<InputField 
max={30}
type="text"
label='First name'
placeholder='John'
name='firstName'
required
onValueChange={(target)=>{
  handleChange(target)
}}
value={inputData.firstName}
/>
</div>
<div className="mb-3">
<InputField 
max={30}
type="text"
label='Last name'
placeholder='Paul'
name='lastName'
required
onValueChange={(target)=>{
  handleChange(target)
}}
value={inputData.lastName}
/>
</div>
{inputData.mobile !== ""?<div className="mb-3">
<InputField 
max={50}
disabled
type="mobile"
label='Mobile number'
placeholder='080000000000'
name='mobile'
dialCode={inputData.dialCode}
flag={inputData.flag}
required
onValueChange={(target)=>{
  handleChange(target)
}}
value={String(inputData.mobile).replace(/[ ]/g,'')}
onClick={()=>{
  setShowPhoneVerication(true)
}}
/>
</div>:null}
</div>
</div>
<div className="mb-3">
<InputField 
max={50}
type="email"
label='Email'
placeholder='name@example.com'
name='email'
required
onValueChange={(target)=>{
  handleChange(target)
}}
value={String(inputData.email).replace(/[ ]/g,'')}
onBlur={()=>{
 
}}
/>
</div>
<div className="mb-3">
<InputField 
max={50}
type="password"
label='Password'
placeholder='*************'
name='password'
required
onValueChange={(target)=>{
  handleChange(target)
}}
pattern={RegExps.password} 
value={String(inputData.password).replace(/[ ]/g,'')}
/>
</div>
<div className="mb-3">
<InputField 
max={50}
type="password"
label='Confirm Password'
placeholder='*******'
name='confirmPassword'
required
pattern={RegExps.password} 
onValueChange={(target)=>{
  handleChange(target)
}}
onBlur={()=>{
  if(inputData.password !== inputData.confirmPassword)
    {
      return ShowMessage("Password does not match.").error()
    }
}}
value={String(inputData.confirmPassword).replace(/[ ]/g,'')}
/>
</div>
{inputData.mobile?<div className="mb-3">
<InputField 
max={10}
label="Mobile phone"
value={inputData.mobile}
onValueChange={(data)=>{
  handleChange(data);
}}
name="mobile"
placeholder="08000000000"
type="mobile"
required
SendToWhatsApp={(d)=>{
   handleChange({name:"sendToWhatsApp",value:d?"1":"0"});
}}
onDialCode={(countryObj)=>{
    handleChange({name:"dialCode",value:String(countryObj.calling_code)});
}}
/>
</div>:null}
<label >Account Type</label>
<div className="mb-3 row p-0 ps-4">
{AccountTypes.sort((a,b)=>(a.name < b.name)?-1:0).filter((a,i)=>!["Admin","Guest","Account"].includes(a.name)).map((a,i)=><div key={i} className="col-lg-12 m-0 p-0  mb-3">
  <div className='alert alert-success d-flex' >
    <div className=''>
    <RadioButtonSingle
    name='accountType'
    onValue={(value)=>{
      handleChange({name:"accountType",value:a.name});
    }}
    required
    value={inputData.accountType === a.name}
    />
  </div>
  <div className='ps-3'>
    <p className='fw-bold'>{a.name}</p>
    {a.description}
    </div>
  </div>
</div>)}
</div>
<div className="mb-3">
<SubmitButton
 title={inputData.mobile !== ""?'Create account':'Continue'}
 type='submit'
 loading={loading}
 fill
/>
</div>
<div className="mb-3 d-flex justify-content-center align-items-center">
<NavLink to={ROUTES.Login}>
I have an account 
</NavLink>
</div>
</div>
</div>
<div className='col-lg-3 col-md-1'></div>
</div>
</div>
</section>
</form>
{showPhoneVerication && <MobileNumberUpdateModal 
onClose={()=>{ 
  setShowPhoneVerication(false)
}}
onSucess={(d:{dialCode:string;mobile:string,country:string,flag:string;verifytoken:string})=>{ 
  const data:UserDataProps = {
    ...inputData,
    dialCode:d.dialCode,
    flag:d.flag,
    mobile:d.mobile,
    country:d.country
  } 
  console.log(data);
  RegisterUser(data);
  setShowPhoneVerication(false); 
}}
noToken={true}
title="Mobile number verification"
/>}
</div>
}
export default SignUpScreen;