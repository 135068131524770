import React, {FormEvent, useState } from "react";
import * as y from "yup";
import BaseModal from "../baseModal";
import { InputField, SubmitButton } from "../forms/inputField";
import { CaretBackIcon } from "../../assets/icons";
import { PostData, SendToast } from "../../includes/function";
import { CountrylistModel, UserDataProps } from "../../includes/types";
const schema = y.object({
  mobile:y.string().required("Mobil number is required.")
})
interface OTPFormProps {
  mobile?:string;
  otp?:string;
  dialCode?:string;
  sendToWhatsApp?:string;
  verifytoken?:string;
}
interface MobileNumberUpdateModalProps {
onClose:(data?:string)=>void;
onSucess:(data:any)=>void; 
title?:string;
noToken?:boolean;
}
const MobileNumberUpdateModal = (props:MobileNumberUpdateModalProps)=>{
const [switchOtp,setSwitchOtp] = useState<boolean>(false);
const [loading,setLoading] = useState<boolean>(false);
const [message,setMessage] = useState<string>("");
const [selectedCountry,setSelectedCountry] = useState<CountrylistModel>({
});
const [formData,setFormData] = useState<OTPFormProps>({
  mobile:"",
  otp:"",
  dialCode:"",
  sendToWhatsApp:"0"
})
const VerifyMobileNumber = (values:OTPFormProps)=>{
setLoading(true);
PostData(props.noToken?"verify_otp":"update_mobile_number",values,true).then((res)=>{
  setLoading(false);
  if(res.status)
  {
    props.onSucess({
      ...values,
      flag:selectedCountry.flag,
      country:selectedCountry.name
    })
  }
})
}
const SendCode = (values:OTPFormProps)=>{
  if(props.noToken)
  {
    setLoading(true);
    PostData("send_otp_to_mobile",values,true).then((res)=>{
      setLoading(false);
      setSwitchOtp(res.status);
      if(res.status)
      {
      setMessage(res.message);
      }
    })
return ;
  }else{
const userData:string | null = localStorage.getItem("userData");
  if(userData)
  {
const u:UserDataProps = JSON.parse(userData);
if(String(u.dialCode)+String(u.mobile) !== String(values.dialCode)+String(parseInt(String(values.mobile))))
{
setLoading(true);
PostData("send_token",values,true).then((res)=>{
  setLoading(false);
  setSwitchOtp(res.status);
  if(res.status)
  {
    setMessage(res.message);
  }
})
  }else{
   SendToast("Same number cannot be updated.",true);
  }
}
  }
}
const handleChange = ({name,value}:{name:string;value:string})=>{
  setFormData({
    ...formData,
    [name]:value
  })
}
return <BaseModal 
onClose={(d)=>{
  props.onClose(d)
}}
type="md"
center={true}
title={props.title?props.title:"Change mobile number"}
>
<form 
 action='post'
onSubmit={(e:FormEvent)=>{
 e.preventDefault();
  if(!switchOtp)
  {
    const data:OTPFormProps = formData;
    delete data.otp;
   return SendCode(data);; 
  }
  if(String(formData.otp).length !== 4)
  {
    return SendToast("OTP must be 4 digits",true);
  }
  VerifyMobileNumber({
    verifytoken:String(formData.otp),
    dialCode:formData.dialCode,
    mobile:String(formData?.mobile)
  })
}}

>
<div >
{switchOtp?<div >
  <div className="" ><small>{message}</small></div>
<InputField 
max={4}
label="OTP"
value={formData.otp}
onValueChange={(data)=>{
  handleChange(data);
}}
name="otp"
type="otp"
required
/>
</div>:<InputField 
max={10}
label="Mobile phone"
value={formData.mobile}
onValueChange={(data)=>{
  handleChange(data);
}}
name="mobile"
placeholder="08000000000"
type="mobile"
required
SendToWhatsApp={(d)=>{
   handleChange({name:"sendToWhatsApp",value:d?"1":"0"});
}}
onDialCode={(countryObj)=>{
    handleChange({name:"dialCode",value:String(countryObj.calling_code)});
    setSelectedCountry(countryObj);
}}
/>}
<div id="recaptcha" style={{opacity:0}} ></div>
<div className="text-center"  >
{switchOtp && <button 
className="btn blueText "
  type="button"
  onClick={()=>{
    setSwitchOtp(false);
    handleChange({name:"sendToWhatsApp",value:"0"})
  }}
  >Resend OTP</button>}
  </div>
<div >
  <SubmitButton 
  loading={loading}
  title={switchOtp?"Verify token":"next"}
  type={"submit"}
  />
  
</div>
</div>
</form>
</BaseModal>
}
export default MobileNumberUpdateModal;
