import { useEffect, useState } from "react";
import { ImageProps } from "../../../components/newlisting/slider";
import { ResourcesProps } from "../../../includes/types";
import { MoreImageIcon } from "../../../assets/icons/moreimageIcon";

interface MoreImagesPreviewProps {
list:ResourcesProps[];
onSelect:(selected:ResourcesProps)=>void;
onPreview:()=>void;
selectedItem:number;
}
const MoreImagesPreview = (props:MoreImagesPreviewProps)=>{
     const [selectedIndex,setSelectedIndex] = useState<number>(props.selectedItem);
     const [list,setList] = useState<ResourcesProps[]>([]);
    useEffect(()=>{
        if(props.list)
        {
            setList(props.list) 
        }
        setSelectedIndex(props.selectedItem)
    },[props])
    return  <div className='imageThumbsContainner'
    >
     <div className="inner">
    {list.filter((a,i)=>i < 3).map((a,i)=><div key={i} onClick={()=>{
     setSelectedIndex(i);
    }}
     className={selectedIndex === i?"imageBigThumbsActive":"imageBigThumbs"} >
      <img 
      src={a.uri}
       />
    </div>)}
    </div>
    {list.length > 2?<div
    onClick={props.onPreview}
    className="show-all-photos">
    <MoreImageIcon size={20} /><span>Show all photos</span>
    </div>:null}
    </div>
}
export default MoreImagesPreview;