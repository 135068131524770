/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import ImagePreview from "../imagePreview";
import { ResourcesProps } from "../../includes/types";
import "./style.css";
import { MoreImagesIcon } from "./icons";
import { blueColor } from "../../includes/constants";
interface MoreImagesProps {
   type:"big"|"text"|"small";
   text?:string;
   list:string[];
   title?:string;
}
export const MoreImages = (props:MoreImagesProps)=>{
    const [imagesList,setImagesList] = useState<ResourcesProps[] | null>(null)
    if(props.list.length <= 1)
   {
    return <></>
   }
    return <>
    {props.type === "text"?<span className="blueText btn"
     onClick={()=>{
       setImagesList(props.list.map((a,i)=>{
        return {
            blob:a,
            uri:a
        }
       }))
      }}>{props.text?props.text:"More"}</span>:props.type === "big" ?<div className='box-wrp-big'>
              <span ><MoreImagesIcon
              color={blueColor} /></span>
        </div>:<div className='box-wrp-sm'
    onClick={()=>{
        setImagesList(props.list.map((a,i)=>{
         return {
             blob:a,
             uri:a
         }
        }))
    }}
    >
    <span >+<MoreImagesIcon
        color={blueColor} 
        size={15}
        /></span>
</div>}
{imagesList && <ImagePreview 
    imageList={imagesList}
    onClose={()=>setImagesList(null)}
    />} 
      </>
    }
    