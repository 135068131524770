/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "../../includes/constants";
import { WalletProps, WindowMessageProps } from "../../includes/types";
import { CheckBoxButton, RadioButton, SubmitButton } from "../forms/inputField";
import { CaretBackIcon } from "../../assets/icons";
import { CheckIfUserLoggedin, PostData, ReturnComma, convertBinToHex } from "../../includes/function";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { BaseLoader } from "../../css/spinnner";
interface PaymentProps {
    url?:string;
    amount:string;
    type:"tour"|"wallet";
    onClose:()=>void;
    onValue:(data:WindowMessageProps)=>void;
    memo?:string;
}

 const PaymentView = (props:PaymentProps)=>{
  const [toggle,setToggle] = useState<boolean>(false);
  const [loading,setLoading] = useState<boolean>(false);
  const [loadingPage,setLoadingPage] = useState<boolean>(true);
  const [loadingWallet,setLoadingWallet] = useState<boolean>(true);
  const [txPin,setTxPin] = useState<string>("");
  const [showTxPin,setShowTxPin] = useState<boolean>(false);
  const [walletDetails,setWalletDetails] = useState<WalletProps>();
  const getWalletDetails = ()=>{
    setLoadingWallet(true);
    PostData("get_wallet_details",{},false).then((res)=>{
    setLoadingWallet(false);
      if(res.status)
      {
        setWalletDetails(res.data)
      }else{
        toast.error(res.message,{
          position:"top-center"
        })
        props.onClose();
      }
    })
  }
  const handleSubmit = ()=>{
    setLoading(true);
    PostData("charge_wallet",{
      amount:props.amount,
      action:props.type,
      txPin
    },true).then((res)=>{
      setLoading(false); 
      if(res.status) 
      {
        props.onClose()
      }
    })
  }
    useEffect(()=>{
      if(CheckIfUserLoggedin().token)
      {
      getWalletDetails();
      }else{
        setLoadingWallet(false);  
      }
      window.addEventListener('message', (message) => {
       props.onValue(message.data);
      },false);
    },[props])
    return <div className="modal">
       <div className="modal-dialog" >
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{toggle?<span onClick={()=>setToggle(false)} className="btn p-0 m-0 text-center blueText" ><CaretBackIcon 
        size={20}
        />
        Back
        </span>:"Payment options"}<br/>
        <small style={{color:"gray",fontSize:12}}>{props.memo}</small>
        </h5>
        
        <button 
        onClick={()=>{
          props.onClose();
        }}
        type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body p-0" style={{height:toggle?580:"auto"}}>
        {loadingWallet?<div className="p-3 d-flex align-items-center justify-content-center" >
          <BaseLoader size="small"/> <small className="px-2">Please wait while we fetch your wallet information...</small>
        </div>:toggle ?<div className="position-relative" style={{width:"100%",height:"100%",textAlign:"center"}}>
          <iframe 
          onLoadStart={()=>setLoadingPage(true)}
          onLoad={()=>setLoadingPage(false)}
        src={`http://127.0.0.1:5000/v1.0/payment_url?amount=${props.amount}&type=${props.type}`}
        style={{width:"100%",height:"100%"}}
        ></iframe>
        {loadingPage && <div 
        style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center",position:"absolute",top:0,paddingTop:120,backgroundColor:"rgba(255,255,255,0.8)"}}
        >
          <div 
        style={{width:80,height:"100%"}}
        >
        <BaseLoader />
        <span className="small">Fetching...</span>
        </div>
        </div>}
        </div>:<div className="p-5">
           <p>Select one of the payment options</p>
          {CheckIfUserLoggedin().token &&<div className="alert alert-success mb-3" >
          <div className="d-flex" >
            <CheckBoxButton 
             onValue={(d)=>{
              setShowTxPin(d)
            }}
            value={false}
            name="payment"
            />
          <div  >
              <label >Pay with wallet</label>
              <div className="px-2">
                <em className="fs-6"><small >This will deducted from your wallet balance</small></em>
              </div>
              <div className="px-2">
                <b>Balance: {walletDetails?.currency} {ReturnComma(String(walletDetails?.balance))}</b>
              </div>
              {showTxPin && <>
              <label className="form-label  mt-3">Enter Transaction PIN</label>
              <div className={"otp d-flex align-items-center justify-content-center"} >
              <OTPInput 
            onChange={(d:any)=>{
              setTxPin(d);
            }}
            value={txPin}
            numInputs={4}
            inputStyle={{width:60,margin:15,height:60,fontSize:20}}
            renderInput={(props)=><input 
              {...props}
              className='form-control'
              placeholder='*'
            />}
            />
              </div>
              <div >
                <SubmitButton
                loading={loading}
                disabled={txPin.length < 4}
                submit={()=>{
                  handleSubmit()
                }}
                title="Send"
                />
              </div>
              </>}
          </div>
          </div>
          </div>}
          {!showTxPin && <>
          <div className="alert alert-success mb-3 pb-0" >
            <div className="d-flex ">
              <CheckBoxButton
              name="payment"
              onValue={(d)=>{
                setToggle(true)
              }}
              value={toggle}
              />
              <div >
              <label >Pay with card</label>
              <div className="px-2">
                <em className="fs-6"><small >This will deducted from your debit, credit card etc.</small></em>
              </div>
            </div>
            </div>
          </div>
          </>}
          </div>}
      </div>
    </div>
  </div>
    </div>
    
}
export default PaymentView;