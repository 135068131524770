/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import './style.css';
import Country from "../../includes/utils/states.json";
import { CityProps, CountryProps, SearchProps, ListOfLatlng } from "../../includes/types";
import SearchIcon from '../../assets/icons/search-icon.svg';
import CaretDown from '../../assets/icons/caret-down.svg';
import MultiRangeSlider from "multi-range-slider-react";
import { AllTypes } from "../../includes/constants";
import { CheckIcon } from "../../assets/icons";
import { ReturnComma } from "../../includes/function";

export const SearhBarComponent = ({onValue,get,type}:{onValue?:(params:SearchProps)=>void,get?:boolean;type?:"land"|"house"|"all"})=>{
  const [selectedSubType,setSelectedSubType] = useState<string[]>(["All"]);
  const [allState,setAllStates] = useState<CountryProps[]>([]);
  const [allCities,setAllCities] = useState<CityProps[]>([]);
  const [selectedState,setSelectedState] = useState<string>("");
  const [selectedCity,setSelectedCity] = useState<string>("");
  const [selectedType,setSelectedType] = useState<string>(type?type:"All");
  const [selectedBed,setSelectedBed] = useState<string>("");
  const [listOfBed,setListOfBed] = useState<string[]>(Array.from({length:10}).map((a,i)=>{
      return String(i+1)
  }));
  const [showState,setShowState] = useState<boolean>(false)
  const [showCity,setShowCity] = useState<boolean>(false)
  const [showType,setShowType] = useState<boolean>(false)
  const [ListOfLatlng,setListOfLatlng] = useState<ListOfLatlng[]>([])
  const [query,setQuery] = useState<string>("")
  const [minAmount,setMinAmount] = useState<number>(1);
  const [maxAmount,setMaxAmount] = useState<number>(100000000);
  const handleSubmit = ()=>{
  const formData:SearchProps[] = [
    {q:query},
    {state:selectedState},
    {city:selectedCity},
    {type:selectedType},
    {sub:selectedSubType},
    {amount:`${minAmount}-${maxAmount}`}
  ]
  const allSearchObjects:any = {}
  const stringValues:string[] = [];
  formData.forEach((a:SearchProps,i:number)=>{
    const value:string = Object.values(a).join("");
    const key:string = Object.keys(a).join("");
    allSearchObjects[key] = value === ""?"all":String(value).toLowerCase();
    if(value !== "")
    {
      stringValues.push(`${key}=${String(value).replace(" ","-")}`);
    }
  })
  if(stringValues.length !== 0)
  {
    if(get)
    {
    window.location.href = String("/search?"+stringValues.join("&")).toLowerCase();
    }else{
      if(onValue)
      {
      onValue(allSearchObjects);
      }
    }
  }
  }
  useEffect(()=>{
  const cn:CountryProps[] = Country as CountryProps[];
  setAllStates(cn);
  },[])
    return <div className="card search-section-container text-center">
    <div className="search-section-inner" >
    <div className="row p-3">
    <div className={`col-5`}>
    <div className="input-group mb-3 ">
     <div className="input-search-wrapper">
        <img src={SearchIcon}
        style={{width:20}}
        />
      <input
      onChange={(d)=>{
        setQuery(d.target.value)
      }}
      placeholder="Search..."
      type="text" className="form-control input-search" aria-label="Dollar amount (with dot and two decimal places)" />
    </div>
    </div>
    </div>
    <div className="col-7">
    <button
    type="button"
    onClick={()=>{
      const list = selectedSubType
      if(list.includes("Rent"))
      {
      return setSelectedSubType(selectedSubType.filter((a,i)=>a !== "Rent"))
      }
      setSelectedSubType([...selectedSubType,"Rent"])
    }}
    className={`btn ${selectedSubType.includes("Rent")?"blueBtn":"blueOutlineBtn"}`}>Rent {selectedSubType.includes("Rent")?<CheckIcon />:null}</button>
    <button
    type="button"
    onClick={()=>{
      const list = selectedSubType
      if(list.includes("Sale"))
      {
      return setSelectedSubType(selectedSubType.filter((a,i)=>a !== "Sale"))
      }
      setSelectedSubType([...selectedSubType,"Sale"])
    }}
    className={`btn ${selectedSubType.includes("Sale")?"blueBtn":"blueOutlineBtn"}`}>Sale {selectedSubType.includes("Sale") ?<CheckIcon />:null}</button>
     <button
    type="button"
    onClick={()=>{
      const list = selectedSubType
      if(list.includes("Short-let"))
      {
      return setSelectedSubType(selectedSubType.filter((a,i)=>a !== "Short-let"))
      }
      setSelectedSubType([...selectedSubType,"Short-let"])
    }}
    className={`btn ${selectedSubType.includes("Short-let")?"blueBtn":"blueOutlineBtn"}`}>Short-let {selectedSubType.includes("Short-let")?<CheckIcon />:null}</button>
    </div> 
    </div>
    <div className="row py-2">
    <div className="col-3 " onClick={()=>{
      setShowState(!showState);
      setShowCity(false);
    }}  >
      <span className="text-start p-3">{selectedState === ""?"All states":selectedState}</span>
      <div className="dropdownItemCaret" >
        <img src={CaretDown}
        style={{width:20}}
        />
     </div>
     {showState && <div className="position-relative" >
     <ul className="dropdown-container" >
        {allState.map((item,i)=><li 
        key={i} >
        <div className=""
        onClick={()=>{
            if(allState)
            {
            setSelectedState(allState[i].name);
            setAllCities(allState[i].cities.map((a,i)=>{
                return {
                    id:i,
                    name:a
                }
            }))
            setSelectedCity("");
            setShowCity(false);
        }
        }}
        >{item.name}</div>
        </li>)}
      </ul>
      </div>}
     </div>
     <div className="col-4" onClick={()=>{
      if(selectedState !== "")
      {
      setShowCity(!showCity);
      setShowState(false);
      }
     }}  >
      <span className="text-start p-3">{selectedCity === ""?"All cities":selectedCity}</span>
      <div className="dropdownItemCaret" >
        <img src={CaretDown}
        style={{width:20}}
        />
     </div>
     {showCity && <div className="position-relative" >
     <ul className="dropdown-container" >
     {allCities.map((item,i)=><li key={i}>
        <div onClick={()=>{
            setSelectedCity(item.name)
        }}>{item.name}</div>
     </li>)}  
      </ul>
      </div>}
     </div>
     <div className="col-5">
     {type === "all"?<>
     {/* <button
    type="button"
    onClick={()=>{
      // setSelectedType(selectedType === "House"?"All":"House")
    }}
    className={`btn ${selectedType === "House" || selectedType === "All"?"blueBtn":"blueOutlineBtn"}`}>House {selectedType === "House"  || selectedType === "All" ?<CheckIcon />:null}</button>
     <button
    type="button"
    onClick={()=>{
      setSelectedType(selectedType === "Land"?"All":"Land")
    }}
    className={`btn ${selectedType === "Land"  || selectedType === "All" ?"blueBtn":"blueOutlineBtn"}`}>Land {selectedType === "Land"   || selectedType === "All" ?<CheckIcon />:null}</button> */}
     </>:<></>}
     </div>
    </div>
    <div className="row px-5">
    <div className=" col-12 pb-2 text-start float-start">Price (<small><b>NGN{ReturnComma(String(minAmount))}</b></small> -  <small><b>NGN{ReturnComma(String(maxAmount))})</b></small>:</div>
    </div>
    <div className="row px-5">
    <div className=" col-12" >
      <MultiRangeSlider 
      minValue={1}
      maxValue={1000000}
      onChange={(v)=>{
      console.log(v)
      setMinAmount(v.minValue === 0?1:v.minValue * 1000000);
      setMaxAmount(v.maxValue * 1000000);
      }}
      step={0.2}
      labels={[
        "N","M"
      ]}
      minCaption={`NGN${ReturnComma(String(minAmount))}`}
      maxCaption={`NGN${ReturnComma(String(maxAmount))}`}
      />
   </div>
   </div>
    <div className="row py-2">
      <div className="col-6 " >
      </div>
    <div className="p-3 pe-5 col-6">
    {/* <button 
    type="button"
    onClick={()=>{
      handleSubmit();
    }}
    className="btn blueOutlineBtn float-right">
        <span className="search-ultra-plus" >
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 330 330" >
        <path d="M325.606 304.394 223.328 202.117c16.707-21.256 26.683-48.041 26.683-77.111C250.011 56.077 193.934 0 125.005 0 56.077 0 0 56.077 0 125.006 0 193.933 56.077 250.01 125.005 250.01c29.07 0 55.855-9.975 77.11-26.681l102.278 102.277c2.929 2.93 6.768 4.394 10.607 4.394s7.678-1.464 10.606-4.394c5.859-5.857 5.859-15.355 0-21.212zM30 125.006C30 72.619 72.619 30 125.005 30c52.387 0 95.006 42.619 95.006 95.005 0 52.386-42.619 95.004-95.006 95.004C72.619 220.01 30 177.391 30 125.006z"></path>
    </svg>
    </span>
        Advance search</button> */}
    <button 
    onClick={()=>{
      handleSubmit()
    }}
    className="btn blueBtn float-right">Search</button>
    </div>
    </div>
    </div>
    </div>
}