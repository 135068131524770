import { useEffect, useState } from "react";
import { ItemModel, SubItemModel } from "../../../includes/types";

interface SelectFieldProps {
    leftIcon?:JSX.Element | string;
    rightIcon?:JSX.Element | string;
    label?:string;
    disabled?:boolean;
    placeholder?:string;
    value:string;
    name:string;
    required?:boolean;
    onValueChange:(d:ItemModel)=>void ;
    options?:ItemModel[] | SubItemModel[] | any[];
    error?:any;
  }
export const SelectField = (props:SelectFieldProps)=>{
    const [value,setValue] = useState<string>("");
    useEffect(()=>{
      // setValue(props.value);
    },[props.value])
    return <div 
        className="mb-3" >
        {props?.label && <label  className="form-label">{props.label}</label>}
        <div className="roundInput mt-0 position-relative" >
          {!props.value &&<input 
          value={props.value}
          required={props.required}
          style={{opacity:0,position:"absolute",left:0,top:0}}
          onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(props.placeholder?props.placeholder:`This option is required.`)}
          onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
          />}
        <select 
        disabled={props.disabled}
        name={props.name}
        required={props.required}
        onChange={({target})=>{
        props.onValueChange({name:target.name,value:target.value})
        }}
        className="fieldText  m-0 position-relative px-3"
        style={{color:props.value?"black":"#ccc"}}
         >
      <option value="" disabled >Select your option</option>
     {props.options?.map((item,i)=><option 
        key={i} 
        selected={props.value === item.value}
        value={item.value}
        >{item.name}</option>)}
      </select>
      </div>
      </div>
    }