/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { AmenitylistProps } from "../../../includes/types";
import { GetData, PostData } from "../../../includes/function";
import { CheckBoxButton } from "../../forms/inputField";
interface AmenitiesProps {
  onValue:(d:AmenitylistProps[])=>void;
}
const AmenitiesComponent = (props:AmenitiesProps)=>{
   const [loading,setLoading] = useState(false)
   const [listOfAmenities,setListOfAmenities] = useState<AmenitylistProps[]>([])
   
   const GetAmenities = ()=>{
        setLoading(true);
        GetData("app_settings",{},false).then((res)=>{
          setLoading(false);
        if(res.status)
        {
          if(res.data.amenities)
          {
            setListOfAmenities(res.data.amenities);
          }
        }
        })
      } 
    useEffect(()=>{
        GetAmenities();
    },[])
    return <div className="row " >
{listOfAmenities.map((a,i)=><div key={i} className="col-3 ly">
  <CheckBoxButton 
  icon={a.icon}
  name={String(a.name)}
  description={a.name}
  value={a.selected?true:false}
  onValue={(d:boolean)=>{
   const x:AmenitylistProps[] = listOfAmenities.map((b,o)=>{
    if(o === i)
    {
       b.selected = d;
    }
    return b
   })
   setListOfAmenities(x);
   props.onValue(x);
  }}
  />
  
</div>)}
    </div>
}
export default AmenitiesComponent;