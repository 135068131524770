/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, RefObject, useEffect, useRef, useState } from "react";
import './style.css';
import { CommentProps, DevelopersProjectProps, DevelopersProps, UserDataProps } from "../../../includes/types";
import { NavLink, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PlaceHolderAvatar, RELOAD, ROUTES, blueColor } from "../../../includes/constants";
import Pagination from "../../../components/pagination";
import { EditableTimeOut, GetData, PostData, RenderHTML } from "../../../includes/function";
import RatingView from "../../../components/rating";
import { BaseLoader } from "../../../css/spinnner";
import { CommentButton } from "../../properties/imageGallery";
import HeartIcon from "../../properties/icons/heart";
import shareIcon from '../../properties/icons/share.svg';
import { DeveloperAppointment } from "./Appointment";
import "./style.css";
import { SubmitButton, TextAreaField } from "../../../components/forms/inputField";
import moment from "moment";
import { MapPinIcon, TrashIcon } from "../../../assets/icons";
import { EditIcon } from "../../../components/icons";
import {Formik} from 'formik';
import * as y from 'yup';
import { MoreImages } from "../../../components/moreImages";
import BaseModal from "../../../components/baseModal";
import AlertBox from "../../../components/dialogBox";
const Schema = y.object({
  comment:y.string().required("Comment is required.").max(1000),
})
const DeveloperDetailScreen = ()=>{
const params = useLocation();
const [loading,setLoader] = useState<boolean>(false);
const [liked,setLike] = useState<boolean>(false);
const [details,setDetails] = useState<DevelopersProps>();
const pCon = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
const [thumbnail,setThumbnail] = useState<string>("");
const [loadingLike,setLoadingLike] = useState<boolean>(false);
const navigate = useNavigate();
const GetDetails = ()=>{
  setLoader(true);
  const id = parseInt(String(params.pathname).split("").filter((a,i,self)=>i === self.length - 1).join(""));
  if(isNaN(id))
  {
    navigate("/"+ROUTES.ErrorPage)
    return;
  }
  PostData("developer_detail",{id:id},false).then((res)=>{
    setLoader(false);
    if(res.status)
    {
    setDetails(res.result);
    setThumbnail(res.result.BackDropImage)
    }else{
      navigate("/"+ROUTES.ErrorPage)
    }
  })
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}
useEffect(()=>{
  GetDetails();
 
},[])
if(loading)
{
  return <div className="p-5 text-center" style={{marginTop:80}}>
    <BaseLoader size="small" />
    <span className="ps-2">fetching data...</span>
  </div>
}
return <main className='main'>
  <div >
    <div className='imageGalleryContainer'
    ref={pCon}
  >
     <img 
    src={thumbnail}
    style={{width:"100%",height:"100%",objectFit:"fill"}}
    /> 
    <div className='imageGalleryBlind p-5 item-details' >
    <div className="company-logo" >
    <img 
    src={details?.logo}
    />  
    </div>
    <div className='row'>
  <div className='col-9'>
 <div className='py-3'>
  <span className='whiteBtnSm'>
    {String("Developer").toUpperCase()}
  </span>
</div>
<div  className={"white-text"}>
<h2><span >{RenderHTML(String(details?.name))}</span> 
</h2>
</div>
<div 
className='address white-text' >
<MapPinIcon
size={20}
/> 
<span className="ps-1">{[details?.officeAddres,details?.city,details?.state].filter((a,i)=>a).join(", ")}</span>
</div>
</div>
<div className='col-3 d-flex pt-5' style={{position:"relative"}}>
<div  
className={'iconsContainer'} 
// onClick={handleWishlist}
> 
{loadingLike?<BaseLoader 
color={blueColor}
size='small'
/>:<HeartIcon 
color={liked?"red":"white"}
size={22}
/>}
</div>
<div  
className='iconsContainer'>
<img 
src={shareIcon} 
style={{width:25,marginLeft:7}}
/>
</div>
<div style={{color: "white",
    fontSize: 10,
    position: "absolute",
    right:85}}>Rate the developer</div>
<span className="mt-3 ms-3">
  <RatingView 
value={parseInt(String(details?.rating))}
onValue={(d)=>{

}}
/>
</span>
</div>
   </div>
    </div>
    {details && <GalleryThumbnail
    {...details!}
    />}
    </div>
    <div className='p-5'>
    <div className='row'>
    <div className='col-lg-8'>
  <div >
    <div className='pb-3'>
    <h5>About Us</h5>
    <p>
    {RenderHTML(String(details?.description))}
    </p>
  </div>
  <div className='pb-3'>
    <h5>Our Services</h5>
    <ul>
    {details?.services?.map((a,i)=><NavLink to={`/search?q=${String(a).toLowerCase()}&type=developers`} className='col-3' ><div >
      <span className='p-2 fs-7 fw-bold' style={{color:blueColor}}>
  <span className='ps-3'>{String(a).toUpperCase()}</span>
        </span></div>
        </NavLink>)}
    </ul>
  </div>
  <div className='pb-3'>
    <h5>Our Projects</h5>
   {details && <ProjectList 
   {...details}
   />}
  </div>
  </div>
  <div className='pb-3'>
    <h5>Office Address on Map</h5>
    <div className='mapContainer' style={{height:300}} >
      <iframe 
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDHlvqtpYvHYYqE-EnifXQGn8KXu8Bc31Y&q=${details?.city},${details?.state} Nigeria`} 
      style={{width:"100%",height:"100%"}}
      ></iframe>
    </div>
  </div>
  <div className='pb-3'>
    <CommentButton 
    id={String(details?.id)}
    postType='developer'
    />
  
  </div>
  </div>
  <div 
  className='col-lg-4 tourSection' 
  >
  {details?.RCNumber && <DeveloperAppointment
  {...details!}
  />}
  </div>
  </div>
  </div>
  </div>
</main>
}
export default DeveloperDetailScreen;

const GalleryThumbnail = (props:DevelopersProps)=>{
  const [loading,setLoading] = useState<boolean>(true)
  const [list,setList] = useState<DevelopersProjectProps[]>([])
  const GetProjects = ()=>{
    PostData("developer_project",{
      rcNumber:props.RCNumber,
      limit:"0,20"
    },false).then((res)=>{
      if(res.status)
      {
      setList(res.result);
    }
    })
   }
   useEffect(()=>{
    GetProjects()
   },[props.RCNumber])
if(list.length === 0)
{
  return <div></div>
}
return <div className="image-thumbnails-container row" >
{list.map((a,i)=><div key={i} className="col-12 p-2" >
{i < 2 &&<div className="card image-thumbnails-card" >
<img src={a.images[0]}
style={{width:"100%",height:"100%",objectFit:"cover"}}
/>
</div>}
{i === 2 && <div className="card image-thumbnails-card">
<img src={a.images[0]}
style={{width:"100%",height:"100%",objectFit:"cover"}}
/>
<MoreImages 
    type="small"
    list={a.images}
    />
</div>}
</div>)} 
</div>
}
const ProjectList = (props:DevelopersProps)=>{
  const [showImages,setShowImages] = useState<string[] | null>(null)
  const [list,setList] = useState<DevelopersProjectProps[]>([])
  const GetProjects = ()=>{
    PostData("developer_project",{
      rcNumber:props.RCNumber,
      limit:"0,20"
    },false).then((res)=>{
      setList(res.result);
    })
   }
   useEffect(()=>{
    GetProjects()
   },[props.RCNumber])
  
  return <div >
    <div className="row me-3" >
  {list.filter((a,i)=>i < 3).map((a,i)=><div key={i} className="pb-3">
<div className="card row" style={{height:100}}>
<div className="col-2 p-2 ">
<div style={{position:"relative",height:80,width:120,overflow:"hidden"}} >
{a.images.filter((b,o)=>o === 0).map((a,i)=><img key={i} className="thumbnail thumbnail-list"
  src={a}
  style={{height:80,width:120,objectFit:"contain"}}
   />)}
 <MoreImages 
    type="small"
    list={a.images}
    />  
    </div>
</div>
<div className="col-10 pt-3">
   <div >{a.name}</div>
    <div className='address' >
  <MapPinIcon
  size={20}
  /> 
  {a?.location}
  </div>
  <div >
    <MoreImages 
    type="text"
    list={a.images}
    />
    <span className="float-right" >
    <span className='address'><b>Start Date: </b>{a?.startDate}</span>
    <span className="px-3">|</span>
    <span className='address'><b>End Date: </b>{a?.startDate}</span>
 </span>
    </div>
   
</div>
</div>
</div>)}
</div>
{list.length > 3?<div style={{marginTop:-8,marginRight:20}}>
  <Pagination 
  numberOfitems={20}
  hasNextPage={false}
  totalItem={list.length}
  />
</div>:null}
</div>}

export const CommentList = ({id,type,list,reload}:{id:string;type:string;list:CommentProps[],reload:()=>void})=>{
  const [selectedComment,setSelectedComment] = useState<string | null>(null);
  const [limit,setLimit] = useState<number>(20);
  const [loading,setLoading] = useState<boolean>(false);
  const [currentUser,setCurrentUser] = useState<UserDataProps>()
 
 const DeleteComment = ()=>{
  setLoading(true);
 PostData("delete_comment",{
  commentId:selectedComment
}).then((res)=>{
  setLoading(false);
  if(res.status)
   {
    setSelectedComment(null);
    reload();
   }
 })
}
 useEffect(()=>{
  const u = localStorage.getItem("userData");
  if(u)
  {
    setCurrentUser(JSON.parse(u));
  }
 },[])

 return <div >
  <div className="row me-3" >
 {list.filter((a,i)=>i < 3).map((a,i)=>{
const editable = EditableTimeOut(a.date!);
return <div key={i} className="pb-3">
<div className="row" style={{height:100}}>
<div className="col-1">
<img  className="avatar"
  src={a.userObject?.avatar?a.userObject.avatar:PlaceHolderAvatar}
  style={{height:40,width:40,objectFit:"contain"}}
   />
</div>
<div className="col-11 pb-3" >
<div className="row comment-bubble">
<div className="col-10">
    <div ><small  >{RenderHTML(a.comment!)}</small></div>
    <div className="fs-8 fw-bold mt-2">{RenderHTML(a.userObject?.firstName+" "+a.userObject?.lastName)}</div>
</div>
<div className="col-2">
  <div className="d-flex justify-content-end align-content-end">
  <EditComment 
  comment={a.comment}
  id={a.id}
  date={a.date}
  reload={()=>{
    reload()
  }}
  />
{editable && <span 
  onClick={()=>{
   setSelectedComment(a.id) 
  }}
  className="px-2 btn pt-0">
    {loading?<BaseLoader size="small"/>:<TrashIcon
    size={16}
    />}
  </span>}
  </div>
<small className="address">{moment(a.date).fromNow()}</small>
</div>
</div>
<div className="row">
<div className="col-2">

</div>
<div className="col-10">
</div>
</div>
</div>
</div>
</div>})}
</div>
{list.length > 3?<div style={{marginTop:-8,marginRight:20}}>
  <Pagination 
  numberOfitems={20}
  hasNextPage={false}
  totalItem={list.length}
  />
</div>:null}
{selectedComment?<AlertBox
title="Alert"
message={`Are you sure you want to delete this comment ?`}
onClose={()=>{
  setSelectedComment(null);
}}
onValue={(d:string)=>{
DeleteComment();
}}
/>:null}
</div>}
export const EditComment = (props:CommentProps)=>{
  const [showModal,setShowModal] = useState<boolean>(false);
  const [loading,setLoading] = useState<boolean>(false);
  const editable = EditableTimeOut(props.date);   
  const [formData,setFormData] = useState<{comment:string}>({
    comment:""
  })
  const handleChange = ({name,value}:{name:string;value:string})=>{
    setFormData({
      ...formData,
      [name]:value
    })
  }
useEffect(()=>{
  if(props.comment)
  {
  setFormData({
    comment:props.comment!
  })
}
},[props.comment])
  if(!editable)
  {
    return <></>
  }
  return <>
  <span 
  onClick={()=>{
    setShowModal(true);
  }}
  style={{backgroundColor:"transparent"}}
  className="px-2 btn p-0 ">
    <EditIcon
    size={22}
    />
  </span>
  {showModal && <BaseModal
        title="Edit a comment"
        onClose={() => setShowModal(false)}
        type="md"
        center
        >
<form
onSubmit={(e:FormEvent)=>{
  e.preventDefault();
    setLoading(true);
    PostData("update_comment",{
      commentId:props.id,
      ...formData
    }).then((res)=>{
      setLoading(false);  
      if(res.status)
      {
        handleChange({name:"comment",value:""});
        props.reload();
        setShowModal(false);
      }
    })
  return ;  
}}
>
<div >
 <TextAreaField
  name="comment"
  label="Comment" 
  max={100}
  required
  onValueChange={(data)=>{
    handleChange(data)
  }}
  value={formData.comment}
 />
 <SubmitButton
 loading={loading}
 title="Submit"
 type="submit"
/>
</div>
</form>
</BaseModal>}
  </>
}