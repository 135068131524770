import React, { RefObject, useEffect, useRef } from "react";
import { ReportView } from "../report";
interface VideoTourFrameProps {
onClose:()=>void;
uri:string;
id?:string;
type?:string;
title?:string;
}
export const VideoTourFrame = (props:VideoTourFrameProps)=>{
    const thisIframe = useRef(null) as RefObject<HTMLIFrameElement>;
useEffect(()=>{
  thisIframe.current?.addEventListener("load", ev => {
    const new_style_element = document.createElement("style");
    new_style_element.textContent = ".ytp-show-cards-title{ display: none; }"
    thisIframe.current?.contentDocument?.head.appendChild(new_style_element);
});
},[thisIframe])
const link = String(props.uri).replace("watch?v=","embed/")
    return <div className='modal' tabIndex={-1}>
    <div className="modal-dialog modal-xl" >
      <div className="modal-content " >
        <div className="modal-header">
          <h5 className="modal-title">Video Tour</h5>
          <button type="button"
          onClick={props.onClose}
          className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body"
        style={{height:"72vh", overflow:"hidden",backgroundColor:"#444",padding:0}}
        >
    <iframe width="100%" height="100%" src={`${link}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>    </div>
  <div className="modal-footer">
  {props.id?<ReportView 
  onValue={()=>{
    return ;
  }}
  postId={String(props.id)}
  postType={String(props.type)}
  title='Report this video'
  />:null}
    </div>
    </div> 
    </div>
    </div>
  }
