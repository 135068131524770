import React, { StrictMode, useEffect, useState } from 'react';
import {NavLink, Navigate, Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { ROUTES, SideBarLinks } from '../../../includes/constants';

const AgentDashboardScreen =()=> {
const location = useLocation();
return <main >
<div className="sidebar">
 {SideBarLinks.map((item,i)=>{
 var selected = false;
 var locName = location.pathname;
 if(locName.includes(String(item.route)) && locName.includes(String("/")))
 {
   selected = true;
 }
 return <NavLink key={i} to={String(ROUTES.UserDashboard+item.route)}>
 <span className="pe-2">{item.icon?item.icon(selected?"White":"black"):null}</span>
 {item.title}</NavLink>})}
</div>
<div className="content px-5" style={{minHeight:"100vh"}}>
<Outlet
/>
</div>
</main>
}
export default AgentDashboardScreen;