/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, StrictMode, useEffect, useRef, useState } from 'react';
import {NavLink, Navigate, useNavigate} from 'react-router-dom';
import './style.css';
import { ItemProps } from '../../../includes/types';
import { GetData, PostData } from '../../../includes/function';
import { CompareIcon, MapPinIcon, ReturnIcons, VideoIcon, VirtualIcon } from '../../../assets/icons';
import HeartIcon from '../../properties/icons/heart';
import { ROUTES, blueColor } from '../../../includes/constants';
import { BaseLoader } from '../../../css/spinnner';
import Pagination from '../../../components/pagination';
import { VideoTourFrame } from '../../../components/videoTour';
import { VirtualTourFrame } from '../../../components/virtualTour';
import { useQuery } from '@tanstack/react-query';

export function SectionTwo() {
const [loading,seTLoading] = useState<boolean>(true)
const [page,setPage] = useState<number>(1);
const [list,setList] = useState<ItemProps[]>(Array.from({length:4}).map((a,i)=>{
  return { }
}))
const [placeholder,setPlaceHolder] = useState<ItemProps[]>(Array.from({length:8}).map((a,i)=>{
  return { }
}))
const {data,isLoading} = useQuery({
  queryKey:['houses'],
  queryFn:()=>GetData("recent_houses",{
    page,
    address:"Nigeria"
  },false)
})
const GetLatestHouses = (limit:number)=>{
  seTLoading(true);
  GetData("recent_houses",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
  seTLoading(false);
if(res.status)
{
  setList(res.result)
}
  })
}
const navigate = useNavigate();
useEffect(()=>{
  // GetLatestHouses(page);
  // alert(JSON.stringify(data))
  if(data?.result)
    {
    setList(data.result)
    }
},[data])
return (<section className="d-flex flex-column p-5 py-2">
<h3>Houses</h3>
<div className='row' >
<div className='col-6' >
<p>Newest listed houses ({list.filter((a,i)=>a.title).length})</p>
</div>
</div>
<div 
className='row'
>
{[...list.filter((item,i)=>i < 8),...placeholder.filter((a,i)=>i > list.filter((item,i)=>i < 8).length - 1)].map((item,i)=><Thumbnail
{...item}
key={i}
/>)}
</div>
{!isLoading && <Pagination  
showMore={()=>{
 return navigate(String(ROUTES.Properties).replace(":type","houses"))
}} />}
</section>)
}

export const Thumbnail = (props:ItemProps)=>{
const [liked,setLiked] = useState<boolean>(false);
const [showVideoTour,setShowVideoTour] = useState<boolean>(false);
const [showIssueModal,setShowIssueModal] = useState<boolean>(false);
const [showVirtualTour,setShowVirtualTour] = useState<boolean>(false);
const [loadingLiked,setLoadingLiked] = useState<boolean>(false);
useEffect(()=>{
  
},[])
useEffect(()=>{
  if(props.liked)
  {
  setLiked(props.liked)
  }
},[props.liked])

if(!props.title)
{
  return  <div 
  className={`col-${props?.numberOfItems?props.numberOfItems:"3"} py-3`}
  >
<div 
className='card list-item position-relative' 
>
  <div className="card-img-top h-thumbnail shimmerAnimation" style={{overflow:"hidden",height:180}} >
  </div>
  <div className="card-body">
    <div 
    style={{width:"100%",height:10,overflow:"hidden"}}
    >
    </div>
    <div 
    className='shimmerAnimation'
    style={{width:"100%",height:10,overflow:"hidden",marginTop:5,marginBottom:5,borderRadius:5}}
    >
    </div>
  <div className='address ' style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
  <MapPinIcon
  size={20}
  color='#ddd'
  /> 
 <div 
 className='shimmerAnimation'
    style={{width:"60%",height:10,overflow:"hidden",marginTop:5,marginBottom:5,borderRadius:5}}
    >
    </div>
  </div>
  <div 
  >
 <div 
 className='shimmerAnimation'
    style={{width:"60%",height:10,overflow:"hidden",marginTop:5,marginBottom:5,borderRadius:5}}
    >
    </div>
  </div>
  </div>
<span 
className='wishlistBtn p-0 d-flex justify-content-center align-items-center'>
<HeartIcon 
color={"#ddd"}
size={20}
/>
</span>
<span className='compareBtn' >
<CompareIcon
color='#ddd'
size={20}
/>
</span>
</div>
 </div>;
}
return <div 
className={`col-${props?.numberOfItems?props.numberOfItems:"3"} py-3`}
>
<div 
className='card list-item position-relative' 
>
<NavLink 
  to={`/${String(props.type).toLowerCase()}/${props.id}`}
  className={"p-0 m-0 "}
  style={{height:180,backgroundColor:"#444"}}
  >
<img 
src={props.thumbnail} 
className="card-img-top h-thumbnail"
 alt={props.id}
 />
</NavLink>
  <div className="card-body">
  <NavLink 
  to={`/${String(props.type).toLowerCase()}/${props.id}`}
  className={"text-truncate"}
  >
  {props.title}
  </NavLink>
  <div className='text-truncate'>{props.description}</div>
  <div className='address' >
  <MapPinIcon
  size={20}
  color={blueColor}
  /> 
  {props.state}, {props.country}
  </div>
  <div 
  >
  <b>{props.currency}{props.price}</b>
  {props.amenities?.filter((a,i)=>i < 4).map((a,i)=>{
  return <span className='amenitiesIcons' key={i} >
  {ReturnIcons(a)}
  </span>})}
  </div>
  </div>
  <div className='position-absolute p-2'>
  <div className="typeView" onClick={()=>{
    if(props.onSearchSub)
    {
      props.onSearchSub(String(props.sub_type))
    }
  }} >
  {`${props.sub_type}`}</div>
  {props?.category &&<span className='whiteBtnSm'>
    {String(props?.category).toUpperCase()}
  </span>}
  {props?.featured &&<span className='blueBtnSm'>
    Featured
  </span>}
  {props?.hot &&<span className='redBtnSm'>
    Hot
  </span>}
  {props?.trendy &&<span className='yellowBtnSm'>
    Trendy
  </span>}
</div>
<div className='icon-container'>
{props?.youtubeLink && <button className='Icons btn-default position-relative'
onClick={()=>{
  setShowVideoTour(true);
}}
>
<VideoIcon
color={blueColor}
size={20}
/>
<div className='tips'>Video tour</div>
</button> }
{props?.virtualTour && <button 
onClick={()=>{
  setShowVirtualTour(true);
}}
className='Icons btn-default position-relative'>
<VirtualIcon
color={blueColor}
size={20}
/>
<div className='tips'>Virtual tour</div>
</button> }
<span 
onClick={()=>{
    setLoadingLiked(true);
    PostData("save_to_wishlist",{
      propertyId:props.id
    }).then((res)=>{
      setLoadingLiked(false);
      setLiked(!liked)
    })
}}
className='Icons position-relative'>
{loadingLiked?<BaseLoader 
size='small'
/>:<HeartIcon 
color={liked?"red":blueColor}
size={20}
/>}
<div className='tips' >Like</div>
</span>
</div>
</div>
{showVideoTour && <VideoTourFrame
onClose={()=>setShowVideoTour(false)}
uri={props.youtubeLink!}
type={String(props.type)}
id={String(props.id)}
/>}
{showVirtualTour && <VirtualTourFrame
onClose={()=>setShowVirtualTour(false)}
type={String(props.type)}
id={String(props.id)}
/>}
</div>
}
export const ListView = (props:ItemProps)=>{
  const [liked,setLiked] = useState<boolean>(false);
  const [showVideoTour,setShowVideoTour] = useState<boolean>(false);
  const [showIssueModal,setShowIssueModal] = useState<boolean>(false);
  const [showVirtualTour,setShowVirtualTour] = useState<boolean>(false);
  const [loadingLiked,setLoadingLiked] = useState<boolean>(false);
 
  useEffect(()=>{
    if(props.liked)
    {
    setLiked(props.liked)
    }
  },[props.liked])
  
  if(!props.title)
  {
    return  <div 
    className={`col-${props?.numberOfItems?props.numberOfItems:"3"} mb-3 mt-3`}
    >
   <div 
    className='card position-relative h-list-item' 
    style={{height:150,overflow:"hidden"}}
    >
    <div 
    className='row' 
    >
    <div className="shimmerAnimation col-lg-2" style={{width:200,height:150,overflow:"hidden",backgroundColor:"#ddd"}} >
    </div>
    <div className='position-relative col-lg-9'>
        <div className="p-3">
        <div style={{overflow:"hidden",width:`${(100/(12/parseInt(String(props?.numberOfItems)))*0.68)}vw`}}>
        <div 
 className='shimmerAnimation'
    style={{width:"70%",height:10,overflow:"hidden",marginTop:5,marginBottom:5}}
    ></div>
     <div 
 className='shimmerAnimation'
    style={{width:"70%",height:10,overflow:"hidden",marginTop:5,marginBottom:5}}
    ></div>
      </div>
      <div className='address ' style={{display:"flex",justifyContent:"flex-start",alignItems:"center",marginTop:20}} >
  <MapPinIcon
  size={20}
  color='#ddd'
  /> 
 <div 
 className='shimmerAnimation'
    style={{width:"60%",height:10,overflow:"hidden",marginTop:5,marginBottom:5}}
    >
    </div>
  </div>
  <div 
 className='shimmerAnimation'
    style={{width:"20%",height:10,overflow:"hidden",marginTop:5,marginBottom:5}}
    ></div>  
      </div>
      <div className='icon-container' style={{position:"absolute",bottom:5,right:10}}>
    <button className='Icons btn-default position-relative'
    >
    <VideoIcon
    color={"#ddd"}
    size={20}
    />
    </button> 
 <span 
    className='Icons position-relative'>
    <HeartIcon 
    color={"#ddd"}
    size={20}
    />
    </span>
    </div>
    </div>
    </div>
    </div>
    </div>;
  }
  return <div 
  className={`col-${props?.numberOfItems?props.numberOfItems:"3"} mb-3 pt-3`}
  >
 <div 
  className='card position-relative h-list-item' 
  style={{height:145,overflow:"hidden"}}
  >
  <div 
  className='row' 
  >
  <div
  className='col-lg-2'
  style={{height:145,overflow:"hidden",backgroundColor:"#444",width:"200px"}} >
  <img 
  src={props.thumbnail} 
  alt="..." 
  style={{width:"100%",height:"100%",objectFit:"fill"}} />
  </div>
  <div className='position-relative col-lg-9'>
  <span className="typeView btn" 
  onClick={()=>{
    if(props.onSearchSub)
    {
      props.onSearchSub(String(props.sub_type))
    }
  }} style={{marginLeft:16,borderBottomLeftRadius:10,borderBottomRightRadius:0}}>{props.sub_type}</span>
    <div className="p-3" style={{marginTop: -10}}>
      <NavLink 
      to={`/${String(props.type).toLowerCase()}/${props.id}`}
      className={"text-truncate fs-5"}
      >
     {props.title}
      </NavLink>
      <div style={{overflow:"hidden",width:`${(100/(12/parseInt(String(props?.numberOfItems)))*0.68)}vw`}}>
    <div className='text-truncate fs-6'>{props.description}</div>
    </div>
    <div className='address' >
    <MapPinIcon
    size={20}
    color={blueColor}
    /> 
    {props.state}, {props.country}
    </div>
    <div 
    >
    <b>{props.currency}{props.price}</b>
    {props.amenities?.filter((a,i)=>i < 4).map((a,i)=>{
    return <span className='amenitiesIcons' key={i} >
    {ReturnIcons(a)}
    </span>})}
    </div>
    </div>
    <div className='icon-container' style={{position:"absolute",bottom:5,right:10}}>
  {!props?.videoTour && <button className='Icons btn-default position-relative'
  onClick={()=>{
    setShowVideoTour(true);
  }}
  >
  <VideoIcon
  color={blueColor}
  size={20}
  />
  <div className='tips'>Video tour</div>
  </button> }
  {!props?.virtualTour && <button 
  onClick={()=>{
    setShowVirtualTour(true);
  }}
  className='Icons btn-default position-relative'>
  <VirtualIcon
  color={blueColor}
  size={20}
  />
  <div className='tips'>Virtual tour</div>
  </button> }
  {localStorage.getItem("token")?<span 
  onClick={()=>{
    
    if(props.liked )
    {
    window.location.href = ROUTES.UserDashboard+"/wishlist"
    }else{
  
      if(!liked)
      {
      setLoadingLiked(true);
      PostData("save_to_wishlist",{
        propertyId:props.id
      }).then((res)=>{
        setLoadingLiked(false);
        if(res.status)
        {
          setLiked(true)
        }
      })
    }else{
      window.location.href = ROUTES.UserDashboard+"/wishlist"
    }
    }
  
  }}
  className='Icons position-relative'>
  {loadingLiked?<BaseLoader 
  size='small'
  />:<HeartIcon 
  color={liked?"red":blueColor}
  size={20}
  />}
  <div className='tips' >Like</div>
  </span>:null}
  </div>
  {showVideoTour && <VideoTourFrame
  onClose={()=>setShowVideoTour(false)}
  uri=''
  type={String(props.type)}
  id={String(props.id)}
  />}
  {showVirtualTour && <VirtualTourFrame
  onClose={()=>setShowVirtualTour(false)}
  type={String(props.type)}
  id={String(props.id)}
  />}
  </div>
  </div>
  </div>
  </div>
  }