/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
import React, { CSSProperties, FormEvent, useState } from "react";
import { SubmitButton, TextAreaField } from "../forms/inputField";
import { Formik } from "formik";
import * as y from 'yup';
import { CheckIfUserLoggedin, PostData } from "../../includes/function";
import { ActionProps } from "../../includes/types";
import { SelectField } from "../forms/inputField/select";
interface ReportViewProps {
style?:CSSProperties | undefined;
onValue:()=>void;
title?:string;
postId:string;
postType:string;
}
interface FormDataProps {
    reason:string;
    note:string;
  }
export const ReportView = (props:ReportViewProps)=>{
const [show,setShow] = useState<boolean>(false);
const [saveObj,setSaveObj] = useState<ActionProps>();
const [formData,setFormData] = useState<FormDataProps>({
  note:"",
  reason:""
});
const [loading,setLoading] = useState<boolean>(false);
return <>
<span className='mt-3' style={props.style} >
{CheckIfUserLoggedin().token && <SubmitButton 
submit={()=>{
  const x = localStorage.getItem("action");
          if(x !== null)
          {
            const v:ActionProps[] = JSON.parse(x) as ActionProps[];
            const value:ActionProps | null = v.find((a,i)=>a.action === "issue") as ActionProps | null;
            if(value)
            {
              setFormData({
                note:String(value?.content),
                reason:String(value?.reason)
              });
            }
          }
  setShow(true);
}}
 title={props?.title?props?.title:'Report this post'}
    />}
</span>
{show && <div 
  className="modal" tabIndex={-1} style={{zIndex: 9999}}>
  <div className="modal-dialog modal-md modal-dialog-centered" >
    <div className="modal-content " >
      <div className="modal-header">
        <h5 className="modal-title">{props?.title?props?.title:'Report this post'}</h5>
        <button type="button" onClick={()=>setShow(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body"
      style={{overflow:"hidden"}}
      >
<form
onSubmit={(e:FormEvent)=>{
  e.preventDefault();
    setLoading(true);
    PostData("report_issues",{
      ...formData,
      postId:props.postId,
      postType:props.postType
    }).then((res)=>{
        setLoading(false);
        setFormData({
          note:"",
          reason:""
        })
        setShow(false);
    })
}}
>
<div >
 <SelectField
 name="reason"
 label="Select reason" 
 value={formData.reason}
 required
 options={[
    {name:"Misleading",value:"Misleading"},
    {name:"Scam",value:"Scam"},
    {name:"Privacy enfringement",value:"Privacy enfringement"},
    {name:"Copyright issues",value:"Copyright issues"}
 ]}
 onValueChange={(d:any)=>{
  setFormData({
    ...formData,
    reason:d.value
  })
 }}
 />
 <TextAreaField
  name=""
  label="Note" 
  max={100}
  required
  value={formData.note}
  onValueChange={({name,value})=>{
    setFormData({
    ...formData,
    note:value
  })
    // const x:ActionProps = {page:"/"+props.postType+"/"+props.postId,action:"issue",content:d.target.value};
    // setSaveObj(x);
    // try {
    //   const obj = localStorage.getItem("action");
    //   if(obj !== null)
    //   {
    //     const v:ActionProps[] = JSON.parse(obj) as ActionProps[];
    //     const nObj = v.map((a:ActionProps,i:number)=>{
    //       if(a.action === "comment")
    //       {
    //        a = x;
    //       }
    //       return a;
    //     })
    //     const allObj:ActionProps[] = v.find((a:ActionProps,i:number)=>a.action === "issue")?nObj:[...nObj,v] as ActionProps[]
    //     setSaveObj(x);
    //     localStorage.setItem("action",JSON.stringify(allObj));
    //   }else{
    //     setSaveObj(x); 
    //     localStorage.setItem("action",JSON.stringify([x]));
    //   }
    //  } catch (error) {
      
    //  }
  }}
 />
 <SubmitButton
 loading={loading}
 title="Submit"
/>
</div>
</form>
  </div>
  </div>
  </div>
  </div> }
</>
}