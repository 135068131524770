/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { ItemProps, ListOfLatlng } from '../../includes/types'
import { ListView, Thumbnail } from '../home/sections/sectionTwo'
import { SearhBarComponent } from '../../components/searchbar'
import MapHandlerComponent from '../../includes/mapHandler'
import { GetData, PostData } from '../../includes/function'
import Pagination from '../../components/pagination'
import ToggleList from '../../components/toggleList'
import { BaseLoader } from '../../css/spinnner'
import { BaseBanner } from '../../components/baseBanner'
import { useParams } from 'react-router-dom'
import { InputField } from '../../components/forms/inputField'
const HomesListingScreen = () => {
  const [filterString,setFilterString] = useState<string>("")
  const [loading,setLoading] = useState<boolean>(false)
  const [selectedView,setSelectedView] = useState<"thumbnail"|"list">("thumbnail")
  const [numberOfitems,setNumberOfitems] = useState<number  | undefined>(0)
  const [totalItem,setTotalItem] = useState<number | undefined>(0)
  const [hasNextPage,setHasNextPage] = useState<boolean  | undefined>(false);
  const [ListOfLatlng,setListOfLatlng] = useState<ListOfLatlng[]>([])
  const thisScrolSection = useRef<HTMLDivElement>(null)
  const [page,setPage] = useState<number>(1);
  const {type} = useParams();
  const [list,setList] = useState<ItemProps[]>(Array.from({length:9}).map((a,i)=>{
      return { }
  }))
const GetLatestHouses = (page:number)=>{
  setPage(page)
  GetData("recent_houses",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
if(res.status)
{
  // const latlng:Item = res.result
  setList(res.result)
  setTotalItem(res.totalItem);
  setHasNextPage(res.hasNextPage);
  setNumberOfitems(res.numberOfitems);
  setListOfLatlng(res.result.map((a:ItemProps,i:number)=>{
    return {
      latitude:a.latitude,
      longitude:a.longitude
    }
  }).filter((a:ListOfLatlng,i:number)=>a.lat && a.lng))
  }
  })
}
const GetLatestLand = (page:number)=>{
  setPage(page)
  GetData("recent_land",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
if(res.status)
{
  // const latlng:Item = res.result
  setList(res.result)
  setTotalItem(res.totalItem);
  setHasNextPage(res.hasNextPage);
  setNumberOfitems(res.numberOfitems);
  setListOfLatlng(res.result.map((a:ItemProps,i:number)=>{
    return {
      latitude:a.latitude,
      longitude:a.longitude
    }
  }).filter((a:ListOfLatlng,i:number)=>a.lat && a.lng))
  }
  })
}
const SearchHouses = (d:string)=>{
setLoading(true);
PostData("search_properties",{searchQuery:d,page},true).then((res)=>{
  setLoading(false);
  if(res.status)
  {
    if(res.result.length !== 0)
    {
      document.body.scrollTop = document.documentElement.scrollTop = 350;
    }
  }
})
}
const divRef = useRef<HTMLDivElement>(null);
const [scrollPosition, setScrollPosition] = useState({ top: 0, left: 0 });
const handleScroll = () => {
  if (thisScrolSection.current) {
    const { scrollTop, scrollLeft } = thisScrolSection.current;
    setScrollPosition({ top: scrollTop, left: scrollLeft });
    console.log(scrollTop)
  }
};

useEffect(()=>{
  setList([]);
if(type)
{
  if(type === "house")
  {
  GetLatestHouses(page);
  }
  if(type === "land")
  {
    GetLatestLand(page);
  }
}
},[type])
const handleSearch = (value:string)=>{
  SearchHouses(value)
}
useEffect(()=>{
    document.body.style.overflow = 'hidden';
    document.querySelector("footer")!.style.display = "none";
    return () => {
      document.body.style.overflow = '';
      document.querySelector("footer")!.style.display = "block";
    };
  }, [document]);

var AllItems = list;
if(filterString !== "")
{
  AllItems = AllItems.filter((a,i)=>a.purpose === filterString)
}

  const placeholder = Array.from({length:12}).map((a,i)=>{
    return {

    }
  })
  AllItems = [...AllItems,...placeholder.filter((a,i)=>i > AllItems.length - 1)]
var Cordinates:ListOfLatlng[] = AllItems.filter((a,i)=>a.latitude).map((a, i) => {
  return {
    lat: a.latitude,
    lng: a.longitude
  }
}) as unknown as ListOfLatlng[]

return (<main className='content' style={{minHeight:600}} >
<div className='row'>
<div className='col-lg-8 p-3'
ref={thisScrolSection}
onScroll={handleScroll}
style={{overflow:"scroll",height:"90vh"}}
>
<div className='pageTitle ms-3'>Residential Properties</div>
<div 
className={`ps-3 ${scrollPosition.top > 45?"sticky":""} `} 
>
<ToggleList 
position={scrollPosition.top}
title={scrollPosition.top > 17?'Search':'Recently listed Homes'}
subList={[]}
selected={selectedView}
onChange={(d)=>setSelectedView(d)}
onFilter={(d:string)=>{
  setFilterString(d)
}}
onSearch={(value)=>{
  handleSearch(value)
}}
/>

</div>
{loading?<div className='p-3'>
  <BaseLoader size={"small"}/>
  <span className="ps-2">Fetching...</span>
</div>:AllItems.filter((a,i)=>a.title).length === 0?<BaseBanner
message="No item found!"
/>:null}
 <div 
className='row ps-3' 
>
{AllItems.map((item,i)=>selectedView === "thumbnail"?<Thumbnail
{...item}
numberOfItems={4}
onSearchSub={(sub:string)=>{
  SearchHouses(sub)
}}
key={i}
/>:<ListView
{...item}
numberOfItems={12}
onSearchSub={(sub:string)=>{
  SearchHouses(sub)
}}
/>)}
</div>
<div className='mb-3 ps-3' >
<Pagination  
onNext={()=>{
  GetLatestHouses(page + 1)
}}
onPrev={()=>{
  GetLatestHouses(page - 1)
}}
numberOfitems={numberOfitems}
hasNextPage={hasNextPage}
totalItem={totalItem}
/>
</div>
 </div> 
 <div className='col-lg-4'>
  <MapHandlerComponent
  coordinates={Cordinates}
  />
 </div>
 </div> 
 </main>
  )
}

export default HomesListingScreen;