const ConvertBlobToImage = (blob:Blob)=>{
    return new Promise<string>((resolve)=>{
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      var base64data:string = reader.result as string;                
      resolve(base64data)
    }
})

}
export default ConvertBlobToImage;

export const ConvertBlobToFile = (blob:Blob,name:string = "newFile")=>{
    console.log(blob)
    return new Promise<File>((resolve)=>{
        const file = new File([blob], name+".png");
        console.log(file)
        resolve(file)
    })
}