import React, { ReactNode, useMemo } from "react";
import ReactApexChart from 'react-apexcharts';
import { UserIcon } from "./icons";
interface DashboardCardViewProp {
title:string;
data:string;
onViewAll?:()=>void;
percentage?:string;
icon?:ReactNode;
}

const DashboardCardView = (prop:DashboardCardViewProp) => {
  const options:any = {
    chart: {
      toolbar: {
        show: false
      },
      height: 100,
      type: 'area', // Type of the chart (Area Chart)
      zoom: {
        enabled: false,
      },

    },
    dataLabels: {
      enabled: false,
    },
    
    yaxis: {
      labels: {
        show: false,
      },
      lines: {
        show: false,
      }
    },
    xaxis: {
      lines: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth', // Creates the spline effect
      width: 2,
    },
    series: [
      {
        name: 'Sales',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Data for the chart
      },
    ],
    fill: {
      type: 'gradient', // Gradient fill for the area
      gradient: {
        shadeIntensity: 0.4,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 90, 100],
      },
    },
    title: {
      text: '',
      align: 'center',
    },
  };
  const  GetColor = ()=>{
  const colors:string[] = ["success","positive","danger","info"];
  // Generate random integer in the inclusive range [min, max]
  const bgColor = Math.floor(Math.random() * ((colors.length - 1) + 1)) + 0;
  return colors[bgColor];
}

return useMemo(()=>{
    return <div className="card custom-card overflow-hidden">
      <div className="card-body">
        <div className="d-flex align-items-top justify-content-between">
          <div className={`icon-wrp icon-wrp-${GetColor()}`}>
           {prop?.icon?prop.icon:<UserIcon color="black" />}
          </div>
          <div className="flex-fill ms-3">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div>
                <p className="text-muted mb-0">{prop.title}</p>
                <h4 className="fw-semibold mt-1">{prop.data}</h4>
              </div>
              <div id="crm-total-customers" style={{ minHeight: 40 }} className="position-relative">
                <div
                  id="apexchartsbpcw7zc1"
                  className="apexcharts-canvas apexchartsbpcw7zc1 apexcharts-theme-light position-absolute"
                  style={{ width: 100, height: 40,top:-50,right:-10 }}
                >
                 <ReactApexChart 
                 options={options} 
                 series={options.series} 
                 type="area" 
                 height={100} 
                 />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1">
              <div>
                <a onClick={prop.onViewAll} className="text-primary fs-7" href="javascript:void(0);">
                  View All
                  <i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"></i>
                </a>
              </div>
              <div className="text-end">
                <p className="mb-0 text-success fw-semibold">{prop?.percentage?prop?.percentage+"%":""}</p>
                <span className="text-muted op-7 fs-11">this month</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>},[])
};
export default DashboardCardView;
