/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React from 'react';
import { toast} from 'react-toastify';
import { ActionProps, PaginationProps, UserLoginProps } from './types';
import { LOCALSTORAGE, ROUTES } from './constants';
import axios from 'axios';
import { ImageProps } from '../components/newlisting/slider';
const ReturnBaseUrl = ()=>{
  return String(window.location.host).includes("localhost")?"http://localhost:3000/v1.0/":"https://api.luxzry-pro.com/v1.0/"
}
const APIBaseUrl = ReturnBaseUrl();
export interface APIResponse {
    status:boolean;
    result:any;
    data?:any;
    message:string;
    totalItem?:any;
    hasNextPage?:any;
    numberOfitems?:any;
    code?:"SUCCESS"|"CHANGE_DEVICE"|"FAILED"|"INACTIVE"|"INVALID_ACCESS_TOKEN";
}

export const SendToast = (message:string,error = false)=>{
if(error)
{
  toast.error(message, {
    position:"top-center"
   }); 
}else{
  toast.success(message, {
    position: "top-center"
   }); 
}
}
export const PostData = (uri:string,data:any,showMessage = true)=>{
  return new Promise<APIResponse & PaginationProps>((resolve)=>{
    const formdata = new FormData();
    Object.keys(data).forEach((ob,i)=>{
      if((ob === "uploads" || ob === "youtube") && Array.isArray(data[ob]))
      {
        const files:any[] = data[ob] as any[]
        files.forEach((file)=>{
        formdata.append(ob,file);
        })
      }else{
      formdata.append(ob,data[ob]);
      }
   })
  var RequestHeaders = {};
   const token = localStorage.getItem(LOCALSTORAGE.Token);
    if(token)
    {
     RequestHeaders = Object.assign(RequestHeaders,{
      "token":token,
      })
    }
    var method:"put"|"post"|"patch" = "post";
    if(String(uri).includes(":"))
    {
      method = String(uri).split(":").filter((a,i)=>0).join("") as "put"|"post"|"patch";
    }
console.log(RequestHeaders);
axios({
    data:formdata,
    url:`${APIBaseUrl}${uri}`,
    method:method,
    validateStatus: function (status) {
    return status < 500;
  },
  headers:RequestHeaders
}).then(({data})=>{
  if(data?.code === "INVALID_ACCESS_TOKEN")
  {
    localStorage.clear();
    const anchor  = document.createElement("a");
    document.appendChild(anchor);
    anchor.href = "/"+ROUTES.Login;
    anchor.click()
  }
  if(data.status)
    {
    if(showMessage)
    {
    toast.success(data.message, {
        position: "top-center"
      });
    }
    }else{
     if(showMessage)
    {
    toast.error(data.message, {
      position: "top-center"
     });  
    }
    }
    resolve(data)
}).catch((e)=>{
    if(showMessage)
    {
    toast.error(e.message, {
        position: "top-center"
       });
    }
    resolve({
        status:false,
        message:e.message,
        result:{}
    }) 
})
})
}

export const CheckIfUserLoggedin = ():UserLoginProps=>{
  const u:string | null = localStorage.getItem("userData");
  const token:string | null = localStorage.getItem("token");
  return {
    token:token !== null,
    userData:JSON.parse(u!)
  }
}
export const GetData = (uri:string,data:any,showMessage = true)=>{
    return new Promise<APIResponse>((resolve)=>{
    const formdata:string[] = [];
    Object.keys(data).forEach((ob,i)=>{
    formdata.push(`${ob}=${data[ob]}`);
   })
  
    const RequestHeaders = new Headers();
   const token = localStorage.getItem(LOCALSTORAGE.Token);
    if(token)
    {
     RequestHeaders.append("token",token)
    }
    const options:RequestInit = {
        headers:RequestHeaders,
        method:"get"
    }
fetch(`${APIBaseUrl}${uri}?${formdata.join("&")}`,options).then((res)=>res.json()).then((res:APIResponse)=>{
  if(res?.code === "INVALID_ACCESS_TOKEN")
  {
    localStorage.clear();
    const anchor  = document.createElement("a");
    document.appendChild(anchor);
    anchor.href = "/"+ROUTES.Login;
    anchor.click()
  }
  if(res.status)
    {
    if(showMessage)
    {
    toast.success(res.message, {
        position: "top-center"
      });
    }
    }else{
     if(showMessage)
    {
    toast.error(res.message, {
      position: "top-center"
     });  
    }
    }
    resolve(res)
}).catch((e)=>{
    if(showMessage)
    {
    toast.error(e.message, {
        position: "top-center"
       });
    }
    resolve({
        status:false,
        message:e.message,
        result:{}
    }) 
})
})
}
const GenerateDeviceId = ()=>{
return convertBinToHex(JSON.stringify({
brand:"dd",
uniIde:"44"
}))
}
export function convertBinToHex(s: string) {
    var i: number;
    var l: number;
    var o: string = '';
    var n: string;
    s += '';
    for (i = 0, l = s.length; i < l; i++) {
      n = s.charCodeAt(i).toString(16);
      o += n.length < 2 ? '0' + n : n;
    }
    return o;
}
export function convertHexToBin(hex: string) {
  return new Promise((resolve, reject) => {
    var string = '';
    for (var i = 0; i < hex.length; i += 2) {
      string += String.fromCharCode(parseInt(hex.substring(i, 2), 16));
    }
    resolve(string);
  });
}
export const MarkMobileNumber = (mobile:string)=>{
   const Splitmobile = String(mobile).split("");
   const maskResult = Splitmobile.map((a,i)=>{
    if([4,5,6,7,8,9].includes(i))
    {
        a = "*";
    }
    return a
   }).join("");

   return maskResult;
}
export const MarkEmail = (email:string)=>{
    const SplitEmail = String(email).split("@");
    const SplitEmail2 = String(SplitEmail[1]).split(".");
    
    let maskResult = String(SplitEmail[0]).split("").map((a,i)=>{
     if(i > SplitEmail[0].length - 5)
     {
         a = "*";
     }
     return a
    }).join("")+"@";
    maskResult += String(SplitEmail2[0]).split("").map((a,i)=>{
        if(i < SplitEmail2[0].length - 3)
        {
            a = "*";
        }
        return a
       }).join("")+"."+SplitEmail2[1]
    return maskResult;
 }

export const ReturnComma = (str:string)=>{
    if(str === "") {
      return str;
    }
    if(str === ".") {
      return String(str).replace('.','');
    }
    if(String(str) === "00"){
      return "0";
    }
    str = String(str).replace(/[^0-9.]/g,'');
    const getDot = String(str).split(".");
    let firstPart = getDot[0];
    if(firstPart.length >= 4) {
       firstPart = firstPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
    if(getDot.length >= 2){
      return firstPart+"."+getDot[1];
    }
    return firstPart;
  }
export const RenderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html:rawHTML } });

export const EditableTimeOut = (date:string)=>{
if(moment(date).isValid())
{
const duration = moment.duration(moment().diff(moment(date)));
return duration.asMinutes() < 5;
}else{
 return false ;  
}
}
export const RedirectToLogin = (props:ActionProps)=>{
    const x = localStorage.getItem("action");
    if(x !== null)
    {
      const v:ActionProps[] = JSON.parse(x) as ActionProps[];
    //   v.forEach((a,i)=>{
    //     if(a.action === props.action)
    //     {

    //     }
    //   })
      localStorage.setItem("action",JSON.stringify(x));
    }
}
export const GenerateUniqueCode = (n:number | undefined)=>{
  if(n === undefined)
  {
    n = 8;
  }
  var text:string = "";
  // var tm:string = new Date().getMilliseconds().toString();
  var possible:string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < n; i++)
  {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const  Greetings = ():string=>{
  var today = new Date()
  var curHr = today.getHours()
  if (curHr < 12) {
    return 'Good morning'
  } else if (curHr < 18) {
    return 'Good afternoon'
  } else {
    return 'Good evening'
  }
}
export const ShowMessage = (message:string)=>{
  return {
    success:()=>toast.success(message, {
    position: "top-center"
  }),
  error:()=>toast.error(message, {
  position: "top-center"
 })
}
}
