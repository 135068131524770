/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';
import { RegExps, ROUTES } from '../../includes/constants';
import {  InputField, SubmitButton } from '../../components/forms/inputField';
import { Formik } from 'formik';
import * as y from 'yup';
import { PostData, ShowMessage } from '../../includes/function';
import OTPInput from 'react-otp-input';
import { InfoIcon } from '../../components/icons';

interface OTPFormProps {
  otp?:string;
  email?:string;
  newPassword?:string;
  confirmPassword?:string;
}
const OTPScreen =()=> {
  const navigate = useNavigate();
  const {email} = useParams();
  const [loading,setLoading] = useState(false);
  const limit:number = 30;
  const [timer,setTimer] = useState<number>(limit);
  const [formData,setFormData] = useState<OTPFormProps>({
    otp:"",
    email:"",
    newPassword:"",
    confirmPassword:""
  })
  const timerRef = useRef<any>();

const Counter = ()=>{
    if(timer !== limit)
    {
        return ;
    }
    timerRef.current = setInterval(()=>{
        setTimer((timer)=>{
            if(timer <= 0)
            {
             clearInterval(timerRef.current);
             return limit;
            }
            return timer - 1;
        })
    },1000)
}
const ResendCode = ()=>{
    if(timer === limit)
    {
        Counter()
    }
}
const handleChange = ({name,value}:{name:string;value:string})=>{
  setFormData({
    ...formData,
    [name]:value
  })
}
  useEffect(()=>{
    if(email)
    {
      if(RegExps.email.test(email))
      {
      Counter();
      }else{
        navigate("/"+ROUTES.ForgotPassword); 
      }
    }else{
      navigate("/"+ROUTES.ForgotPassword);
    }
  },[])
  
  return (<form  
    onSubmit={(e:FormEvent)=>{
    e.preventDefault();
    if(formData.newPassword !== formData.confirmPassword)
    {
      return ShowMessage("Password not match.").error()
    }
    setLoading(true);
    PostData("change_password",{
      email,
      otp:formData.otp,
      newPassword:formData.confirmPassword
    }).then((res)=>{
    setLoading(false);
      if(res.status)
      {
     navigate(ROUTES.Login)
      }
    })
    }}
    
    >
    <section  className="p-5 py-2 blueSection">
        <div className='container d-flex pt-5' >
        <div className='row pt-5' >
        <div className='col-lg-3'></div>
        <div className='col-lg-6'>
        <div className='row pt-5' >
        <h4 className='pt-5 text-center mb-3'>OTP Verification</h4>
        <div className="mb-3 d-flex justify-content-center">
      <OTPInput 
      onChange={(d)=>{
        handleChange({name:"otp",value:d})
      }}
      value={formData.otp}
      inputStyle={{width:80,margin:15,height:60,fontSize:20}}
      renderInput={(props)=><input 
        {...props}
        className='form-control'
        placeholder='*'
      />}
      />
    </div>
    <div className="mb-3">
    <InputField 
    max={50}
    type="password"
    label='New Password'
    placeholder='*************'
    name='newPassword'
    required
    onValueChange={(data)=>{
      handleChange(data)
    }}
    value={String(formData.newPassword!).replace(/[ ]/g,'')}
    />
    </div>
    <div className="mb-3">
    <InputField 
    max={50}
    type="password"
    label='Confirm Password'
    placeholder='*************'
    name='confirmPassword'
    required
    onValueChange={(data)=>{
      handleChange(data)
    }}
    value={String(formData.confirmPassword).replace(/[ ]/g,'')}
    />
    </div>
        <div className="mb-3">
        <p className='text-center'><span onClick={ResendCode} className={timer !== limit?'':'timerText_'} >{timer === 30?"Resend code":"Please wait for : "}</span> <span className={'timerText'}>{timer !== limit?timer:""}</span>{timer !== limit?"sec":""}</p>
        </div>
        <div className="mb-3 text-center">
        <SubmitButton
        fill
        title='Verify'
        type={"submit"}
        loading={loading}
        />
        </div>
        </div>
        </div>
        <div className='col-lg-3'></div>
        </div>
        </div>
    </section>
    </form>)
}
export default OTPScreen;