/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { CheckIcon } from "../../assets/icons";
import { NewHomeListing } from "./newPost";
import { VideoTourFrame } from "../videoTour";
export type SwitchSection = "House"|"Land"
interface NewListingProps {
    onClose:()=>void;
}
// import 'react-photo-editor/dist/style.css ;'
export const NewListing = (props:NewListingProps)=>{
const [section,setSection] = useState<SwitchSection>('House')
const [youtubePreviewLink,setYoutubePreview] = useState<string>('')

return <div 
className="modal" 
  tabIndex={-1} >
<div className="modal-dialog modal-lg modal-dialog-scrollable" >
<div className="modal-content" style={{marginTop:120}}>
<div className="modal-header" >
    <h5 className="modal-title">Post a property</h5>
    <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> 
</div>
<div className="modal-body p-5" >
<div >
<div className="btn-wrapper d-flex"
>
      <div 
      onClick={()=>{
        setSection("House");
      }}
      className={section === "House"?"tab-btn-active":"tab-btn"}>
        <span className="pe-1">House</span> {section === "House"?<CheckIcon />:null}
      </div>
      <div
      onClick={()=>{
        setSection("Land");
      }}
      className={section === "Land"?"tab-btn-active":"tab-btn"}>
        <span className="pe-1">Land</span>{section === "Land"?<CheckIcon  />:null}
      </div>
    </div>
<NewHomeListing 
onClose={props.onClose} 
propertyType={section}
onSuccess={()=>{
props.onClose()
}}
onYoutubePreview={(link)=>setYoutubePreview(link)}
/>
{youtubePreviewLink !== ""?<VideoTourFrame 
uri={youtubePreviewLink}
onClose={()=>setYoutubePreview("")}
type="lg"
title=""
id=""
/>:null}
</div>
</div>
</div>
</div>
</div>
}

