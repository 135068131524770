/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext,RefObject, useEffect, useRef, useState, FormEvent } from 'react'
import './style.css';
import compareIcon from './icons/compare.svg';
import { ActionProps, AgentDetailProps, CommentProps, DevelopersProps, ItemProps, ResourcesProps, UserDataProps } from '../../includes/types';

import mapPin from '../../assets/icons/map-pin.svg'
import { NavLink, Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AddressIcon, EditIcon, EmailIcon, MobileIcon } from '../../components/icons';
import { AddListingIcon } from '../../images';
import { CheckIfUserLoggedin, GetData, MarkEmail, MarkMobileNumber, PostData, SendToast } from '../../includes/function';
import { CheckIcon, CloseIcon, ReturnIcons, TrashIcon, VideoIcon, VirtualIcon } from '../../assets/icons';
import { PlaceHolderAvatar, RELOAD, ROUTES, blueColor } from '../../includes/constants';
import { AppContextModel } from '../../components/appContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as y from 'yup';
import Moment from 'moment';
import { BaseLoader } from '../../css/spinnner';
import { SubmitButton, TextAreaField } from '../../components/forms/inputField';
import PaymentView from '../../components/payment';
import Pagination from '../../components/pagination';
import moment from 'moment';
import { ReportView } from '../../components/report';
import { CommentList } from '../developers/details';
import { loginBtnRef } from '../../App';
import { VideoTourFrame } from '../../components/videoTour';
import { VirtualTourFrame } from '../../components/virtualTour';
import { toast } from 'react-toastify';
import { SelectField } from '../../components/forms/inputField/select';
import MapHandlerComponent from '../../includes/mapHandler';
import HeartIcon from '../properties/icons/heart';
import { BookATour, CommentButton } from '../properties/imageGallery';

export interface ImgProps {
  uri:string;
  name:string;
  type:string;
}
const ImagePropertyGallery = () => {
  const [loading,setLoading] = useState<boolean>(false);
  const [showVideoModal,setShowVideoModal] = useState<boolean>(false);
  const [showVirtualModal,setShowVirtualModal] = useState<boolean>(false);
  const [liked,setLike] = useState<boolean>(false);
  const [loadingLike,setLoadingLike] = useState<boolean>(false);
  const [selectedIndex,setSelectedIndex] = useState(0);
  const [details,setDetails] = useState<ItemProps>({})
  const [thumbnail,setThumbnail] = useState<string>("")
  const [list,setList] = useState<ResourcesProps[]>([])
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const pCon = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const {id} = useParams();
  const GetDetails = ()=>{
    setLoading(true);
    GetData("land_details",{
      id:id
    },false).then((res)=>{
      setLoading(false);
    if(res.status)
    {
      setThumbnail(res.data.thumbnail)
      setDetails(res.data)
      setLike(res.data.liked)
      setList([{uri:res.data.thumbnail},...res.data.images]);
    }else{
      toast.error(res.message,{
        position:"top-center"
      })
      navigate(ROUTES.LandsScreen)
    }
  })
  }

  const handleWishlist = ()=>{
    setLoadingLike(true);
    PostData("save_to_wishlist",{
      propertyId:id
    }).then((res)=>{
      setLoadingLike(false);
      setLike(!liked);
    })
  }

  useEffect(()=>{
    GetDetails();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])

  useEffect(()=>{
    var index = 0;
    const x = setInterval(()=>{
      if(details.images)
      {
    const all:string[] = [details.thumbnail, ...details.images?.map((a, i) => a.uri)].filter((a, i) => a !== undefined) as string[]
    console.log(all)
      if(index >= all.length - 1)
      {
        index = 0;
      }else{
       if(all.length !== 0 )
      {
        index += 1;
      }
      }
      setSelectedIndex(index)
      const found = all.find((a,i)=>i === index);
      console.log(found,"|",index)
      if(found)
      {
      setThumbnail(found)
      }
    }
    },3000)
    return ()=>{
      clearInterval(x);
    }
  },[thumbnail])
if(!pathname || String(pathname).split("/").length !== 3)
{
  return <Navigate to="/" />
}
if(loading)
{
  return <div className='p-5'>
    <span className='small px-2' >Fetching details...</span>
    <BaseLoader />
  </div>
}

  return (<div >
    <div className='imageGalleryContainer'
    ref={pCon}
  >
     <img 
    src={thumbnail}
    style={{width:"100%",height:"100%",objectFit:"cover"}}
    /> 
    <div className='imageThumbsContainner'>
    {list.map((a,i)=><div key={i} onClick={()=>{
    //  setDisplayResources(a);
     setSelectedIndex(i);
     setThumbnail(String(a.uri));
    }}
     className={`${selectedIndex === i?"imageThumbsActive":"imageThumbs"}`} >
      <img 
      src={a.uri}
       />
    </div>)}
    </div>
    <div className='imageGalleryBlind p-5 item-details' >
    <div className='row'>
  <div className='col-8'>
 <div className='py-3'>
  <span className='whiteBtnSm'>
    {String(details?.type).toUpperCase()}
  </span>
  {details?.featured && <span className='blueBtnSm'>
    Featured
  </span>}
  {details?.hot && <span className='redBtnSm'>
    Hot
  </span>}
  {details?.trendy && <span className='yellowBtnSm'>
    Trendy
  </span>}
</div>
<div  className={"white-text"}>
<h2><span >{details?.title}</span> 
</h2>
</div>
<div 
className='address white-text' >
<img src={mapPin} 
style={{width:18}}
/> 
{details?.state}, {details?.country} <span className='priceDetails' >
{details?.currency}{details?.price}
</span>
</div>
</div>
<div className='col-4 d-flex pt-5'>
<div  
className={'iconsContainer position-relative justify-end'} 
onClick={handleWishlist}
> 
{loadingLike?<BaseLoader 
color={blueColor}
size='small'
/>:<HeartIcon
color={liked?"red":"white"}
size={22}
/>}
<div className='tips'>Like</div>
</div>

{/* <div  
className='iconsContainer position-relative' >
<PrinterIcon
size={25}
/>
<div className='tips'>Print</div>
</div> */}

{details.virtualTour?<div  
className='iconsContainer position-relative' 
onClick={()=>setShowVirtualModal(true)}
>
<VirtualIcon
size={25}
/>
<div className='tips'>Virtual Tour</div>
</div>:null}
{details.youtubeLink?<div  
className='iconsContainer position-relative'
onClick={()=>setShowVideoModal(true)}
>
<VideoIcon 
color='white'
size={25}
/>
<div className='tips'>Video Tour</div>
</div>:null}
<ReportView
onValue={()=>{
return ;
}}
postId={details.id!}
postType={details.type!}
/>
</div>
   </div>
    </div>
    </div>
    <div className='p-5'>
    <div className='row'>
    <div className='col-lg-8'>
  <div >
    <h5>Overview</h5> 
    <div className='pb-3'>
{details?.amenities?.filter((a,i)=>i < 5)?.map((a,i)=>{
  return <span className='amenitiesIconsLarge lightBlue' key={i} >
  <div className='d-flex justify-content-center align-item-center' >
  <img
  src={a.icon} 
  style={{width:25}}
  />
  <span className='ps-2 text-uppercase fs-7'>{a.name}</span>
  </div>
  </span>})}
 </div>
    <div className='pb-3'>
    <h5>Description</h5>
    <p>
    {details?.description}
    </p>
  </div>
  <div className='pb-3'>
    <h5>Size: </h5>
    <p className='blueText'>{details.size} sqm</p>
  </div>
  </div>
  <div className='pb-3'>
    <h5>Location on Map</h5>
    <div className='mapContainer' style={{height:300}}  >
     <MapHandlerComponent 
     coordinates={[
      {lat:parseFloat(String(details?.latitude)),lng:parseFloat(String(details?.latitude))}
      ]}
      zoom={5}
      />
     
    </div>
  </div>
  <div className='pb-3'>
   <CommentButton 
    id={String(details?.id)}
    postType='land'
    />
  </div>
  </div>
  <div 
  className='col-lg-4 tourSection' 
  >
  <BookATour
  agentObject={details.agentObject!}
  propertyId={String(details?.id)}
  />
  </div>
  </div>
  </div>
  {showVideoModal && <VideoTourFrame 
  id={String(details?.id)}
  onClose={()=>setShowVideoModal(false)}
  uri={String(details?.youtubeLink)}
  type={String(details?.type)}
  />}
   {showVirtualModal && <VirtualTourFrame
  id={String(details?.id)}
  onClose={()=>setShowVirtualModal(false)}
  type={String(details?.type)}
  />}
  </div>
  )
}

export default ImagePropertyGallery;
