/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ItemProps, SearchProps } from '../../includes/types'
import { Thumbnail } from '../home/sections/sectionTwo'
import { SearhBarComponent } from '../../components/searchbar'
import {useSearchParams } from 'react-router-dom'
import { PostData, ReturnComma } from '../../includes/function'
import Pagination from '../../components/pagination'
import { FilterIcon } from '../../assets/icons'
import FilterComponent from '../../components/filterCompent'
import { BaseLoader } from '../../css/spinnner'

const SearchScreen = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [list,setList] = useState<ItemProps[]>([]);
  const [savelist,setSaveList] = useState<ItemProps[]>([]);
    const SearchAll = (data:SearchProps,limit:string)=>{
      setLoading(true);
      if(!data.q)
      {
        data.q = "all"
      }
      if(!data.city)
      {
        data.city = "all"
      }
      if(!data.state)
      {
        data.state = "all"
      }
      if(!data.sub)
      {
        data.sub = ["all"]
      }
      if(!data.type)
      {
        data.type = "all"
      }
      if(!data.amount)
      {
        data.amount = "1-100000000"
      }
      data.country = "Nigeria"
      setList([]);
      PostData("search_all",{
        ...data,
        limit:limit
      },false).then((res)=>{
        setLoading(false);
        if(res.status)
        {
          setList(res.result);
          setSaveList(res.result);
        }
      })
    }
    useEffect(()=>{
      const sub = String(searchParams.get("type")?.toString())
      const AllQuery:SearchProps = {
        q:searchParams.get("q")?.toString(),
        city:searchParams.get("city")?.toString(),
        state:searchParams.get("state")?.toString(),
        country:"Nigeria",
        type:searchParams.get("type")?.toString(),
        sub:[sub],
        amount:searchParams.get("amount")?.toString()
      }
      SearchAll(AllQuery,"0,50")
    },[])
    
  return (<main className='content' style={{minHeight:600}} >
        <div className='px-5' >
        <div className='pageTitle'>Search </div>
        <div className='p-3 blueSection' >
        <SearhBarComponent 
        onValue={(d:SearchProps)=>{
        SearchAll(d,"0,50")
      }}
/>
</div>
<div className='d-flex justify-content-end'>
  <span className='pt-2'>Filter by:</span>
 <FilterComponent
 position="right"
 onValue={(v)=>{
  setList(savelist.filter((a,i)=>a.type === v.value));
  return ;
 }}
 list={[
  {name:"Houses",value:"House"},
  {name:"Lands",value:"Land"}
 ]}
 />
</div>
</div>
<div className='text-center mb-3'>
  {loading &&<BaseLoader  size='small'/>}
  <span className='p-2'>{loading?"Searching...":"Result"} of your search is showing below</span>
</div>
{!loading && list.length === 0?<div className="alert alert-danger m-3">
  Result not found!
</div>:null}
<div 
className='row px-5'
>
{list.filter((item,i)=>i < 8).map((item,i)=><Thumbnail
{...item}
key={i}
/>)}
</div>
<div className='mb-3 mx-5' >
<Pagination />
</div>
    </main>
  )
}

export default SearchScreen;