/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, ChangeEventHandler, FormEvent, ReactElement, RefObject, useEffect, useRef, useState } from "react";
import { ResourcesProps } from "../../../includes/types"
import { MoreImagesIcon } from "../../moreImages/icons";
import "./index.css";
import Dropzone from "react-dropzone";
import { PostData } from "../../../includes/function";
import useHttpHooks from "../../../includes/httpHooks";
import { TrashIcon } from "../../../assets/icons";
import { EditIcon } from "../../icons";

import BaseModal from "../../baseModal";
import ImageEditorComponent from "../../imageEditor";
import { BaseLoader } from "../../../css/spinnner";
import { useTransition, animated } from '@react-spring/web'
import SubscriptionPlans from "../../subscription_plans";
import ConvertBlobToImage, { ConvertBlobToFile } from "../../imageEditor/image-manipulations";


interface ImageSliderProp {
    updateImages:(list:Blob[])=>void;
    numberOfImagesSubscribed:number;
}
export interface ImageProps {
  blob?:Blob;
  file?:File;
  url?:string;
}
export const ImageSlider = (prop:ImageSliderProp)=>{

    const [listOfBlobImages,setlistOfBlobImages]= useState<Blob[]>([]);
    const [editedIndex,setEditedIndex]= useState<boolean>(false);
    const [imageIndex,setImageIndex] = useState<number>(0);
    const [selectedImage,setSelectedImage]= useState<string>("");
    const [selectedFile,setSelectedFile]= useState<File | null>(null);
    const innerContainer = useRef<HTMLDivElement>(null);
    const [displayImg,setDisplayImg] = useState<string>("");
    const handleImageSelection = (f:File)=>{
      setSelectedFile(f);
      setEditedIndex(false);
    }
    useEffect(()=>{
        prop.updateImages(listOfBlobImages);
    },[listOfBlobImages]);
  
    const ImageCount = prop.numberOfImagesSubscribed - listOfBlobImages.length;
    return <>
    
    <div className="sliderContainer">
    {listOfBlobImages.length === 0? <div className="sliderImage" 
    >
    <Dropzone 
      accept={{
        "image/*": ['.jpeg', '.png']
      }
      }
    onDrop={acceptedFiles => {
        handleImageSelection(acceptedFiles[0])
        }}>
    {({getRootProps, getInputProps}) => (<>
    <div className="imageItem text-center position-relative"  {...getRootProps()} >
           <input {...getInputProps()} />
           <div className="mt-5 mb-3">Select or Drag and drop image</div> 
            <span className="cursor" >
            <MoreImagesIcon size={40}/>
            </span>
            <input 
          required
          onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Select an image or drag and drop.')}
          onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
          style={{opacity:0,position:"absolute"}}
          />
     </div>
     </>)}
     </Dropzone>
     </div>:<div 
     ref={innerContainer}
     className="sliderImage">
   <div 
     className={`imageItem active`}  >
      <img 
      src={selectedImage}
      />
     </div>
    </div>}
    {listOfBlobImages.length !== 0 && ImageCount !== 0?<div className="add-more-icon box-shadow">
        <Dropzone 
        accept={{
            "image/*": ['.jpeg', '.png']
          }
          }
     onDrop={acceptedFiles => {
      handleImageSelection(acceptedFiles[0])
         }}>
       
       {({getRootProps, getInputProps}) => (<div {...getRootProps()}>
     <input {...getInputProps()} />
     <MoreImagesIcon />
     <span className="px-2">{`Add ${ImageCount} more image${ImageCount !== 1?"s":""}`}</span>
     </div>)}
     </Dropzone>
     </div>:null}
     {listOfBlobImages.length > 1?<div className="slider-indicator box-shadow" >
    {listOfBlobImages.map((a,i)=><div 
    onClick={()=>{
      ConvertBlobToImage(a).then((img)=>{
        setSelectedImage(img);
        setImageIndex(i)
      })
    }}
    className={i === imageIndex?"activeDot":"inactiveDot"} key={i} ></div>)}
    </div>:null}
    {listOfBlobImages.length !== 0 &&<div className="slider-icon-wrpper box-shadow">
    <div 
      onClick={()=>{
        setEditedIndex(false);
        const list = listOfBlobImages.filter((a,i)=>i !== imageIndex)
        setlistOfBlobImages(list);
        const firstItem = list.find((a,i)=>i === 0)
        if(firstItem)
        {
        ConvertBlobToImage(firstItem).then((img)=>{
        setImageIndex(0)
        setSelectedImage(img);
        })
        }
      }}
      className="slider-icon">
      <TrashIcon size={20}/>
      </div>
         <div
         onClick={()=>{
           setEditedIndex(true);
           const firstItem = listOfBlobImages.find((a,i)=>i === imageIndex)
           ConvertBlobToFile(firstItem!).then((file)=>{
            setSelectedFile(file);
           })
         }}
         className="slider-icon">
            <EditIcon size={15}/>
         </div>
         </div>}
    </div>
    {selectedFile !== null ?<ImageEditorComponent 
    onClose={()=>setSelectedFile(null)}
    onValue={(editedImage:Blob)=>{
        var countImages:Blob[] = [];
        if(editedIndex)
        {
            countImages = listOfBlobImages.map((a,i)=>{
                if(i === imageIndex)
                {
                    a = editedImage;
                }
                return a;
            })
        }else{
        countImages = [...listOfBlobImages,editedImage];
        }
        ConvertBlobToImage(editedImage).then((image:string)=>{
          setSelectedImage(image);
          setlistOfBlobImages(countImages);
          setSelectedFile(null);
        })
    }}
    file={selectedFile}
    />:null}
    
    </>
}
