/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useEffect, useState } from 'react';
import {NavLink, useNavigate, useSearchParams} from 'react-router-dom';
import { ROUTES} from '../../includes/constants';
import { InputField, SubmitButton } from '../../components/forms/inputField';
import { Formik } from 'formik';
import * as y from 'yup';
import { PostData } from '../../includes/function';
import { useDispatch } from 'react-redux';
import { UserDataProps } from '../../includes/types';

const ForgotPasswordScreen = () => {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
  window.scrollTo({behavior:"smooth",top:0})
  },[])
  const [formData,setFormData] = useState<UserDataProps>({
    email:""
    })
  const handleChange = ({name,value}:{name:string;value:string})=>{
    setFormData({
      [name]:value
    })
  }
  return (<form
    onSubmit={async(e:FormEvent)=>{
    e.preventDefault();
    setLoading(true);
    PostData("forgot_password",formData).then((res)=>{
    setLoading(false);
      if(res.status)
      {
        navigate(`/${String(ROUTES.OTP).replace(":email","")}${formData.email}`);
      }
    })
    }}
   
    >
<section  className="p-5 py-2 blueSection">
    <div className='container p-5' >
    <div className='row p-5' >
    <div className='col-lg-3 col-md-1' ></div>
    <div className='col-lg-6 col-md-10  card rounded-5'>
    <div className='row p-5' >
    <h4 className='pb-5'>Forgot password</h4>
    <InputField 
    max={50}
    type="email"
    label='Email'
    placeholder='name@example.com'
    name='email'
    required
    onValueChange={(data)=>{
      handleChange(data)
    }}
    value={String(formData.email).replace(/[ ]/g,'')}
    />
    <div className="my-3">
    <SubmitButton
     title='Continue'
     type={"submit"}
     loading={loading}
    />
    </div>
    <div className='text-end'>
     <NavLink to={ROUTES.Login}>
    I have account
    </NavLink>
    </div>
    </div>
    </div>
    <div className='col-lg-3 col-md-1' ></div>
    </div>
    </div>
    </section>
    </form>)
}
export default ForgotPasswordScreen;