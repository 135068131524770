/* eslint-disable jsx-a11y/alt-text */
import React, {  useRef, useState } from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import BaseLogo from '../../images/luxzry-pro-logo.png';
import { AppName, TabLinks } from '../../includes/constants';
import './style.css';
import { FacebookIcon, InstagramIcon, WhatsAppIcon } from './icons';
import { Formik } from 'formik';
import * as y  from 'yup';
import { GetData } from '../../includes/function';
import { BaseLoader } from '../../css/spinnner';
import ChatComponent from '../chat';
import ImageEditorComponent from '../imageEditor';
const Schema = y.object({
    email:y.string().matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,"A valid email is required").required("A valid email is required").email("A valid email is required")
})
export default function Footer() {
    const thisForm = useRef<any>(null);
    const location = useLocation();
    const [loading,setLoading] = useState<boolean>(false)
    if(String(location.pathname).includes("dashboard"))
    {
        return <></>
    }
return <footer 
className='footer'
>
<div className='row p-5' >
    <div className='col-5' >
        <span className='pb-3'>
        <img src={BaseLogo} 
        style={{width:39}}
        />
        </span>
        <span className='appName-white'>{AppName}</span>
        <p >Experience the epitome of flexibility and empower your real estate ventures with our feature-rich theme.<br />
        <br />Effortlessly adapt to various property types and market demands with our comprehensive solution.</p>
    </div>
    <div className="col-3">
    <ul className="footerLink">
      {TabLinks.filter((a,i)=>i < TabLinks.length - 3).map((a,i)=>a.submenu?<></>:<li key={i} className={`footer-nav-item`} >
        <NavLink className={`nav-link `} aria-current="page" to={String(a.route)} role="button"   aria-expanded="false">{a.title}</NavLink>
      </li>)}
    </ul>
    </div>
    <div className="col-4">
    <div className='d-flex justify-content-end algin-items-start' >
        <button className='btn socialIcon' >
        <FacebookIcon size={50} />   
        </button>
        <button className='btn socialIcon' >
        <WhatsAppIcon size={40} />   
        </button>
        <button className='btn socialIcon' >
        <InstagramIcon size={30} />   
        </button>
    </div>
    <div className='ps-5'>
        <span>Subscribe to newsletter</span>
    </div>
    <Formik
    innerRef={thisForm}
    validationSchema={Schema}
    onSubmit={(values)=>{
    setLoading(true);
    GetData("newsletter",values,true).then((res)=>{
    setLoading(false);
        if(res.status)
        {
            thisForm.current.setFieldValue("email","")
        }
    })
    }}
    initialValues={{
        email:"",
        status:"1"
        }}
    >
    {({values,handleSubmit,handleChange})=><>
    <div className='newsletterContainer d-flex justify-content-end algin-items-start' >
        <input
        type='email'
        placeholder='youremail@email.com'
        className='newsletterInput'
        onChange={handleChange("email")}
        value={values.email}
         />
        <button
        type='button'
        onClick={()=>handleSubmit()}
        className='newsletterButton'
        style={{width:80}}
         >
       {loading?<BaseLoader size='small' />:"Send"}
       </button>
    </div>
    </>}
    </Formik>
    </div>
</div>
<div className='row p-5' >
    <b>copyright &copy;{AppName}.com</b>
</div>

</footer>
}