/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { StrictMode, useEffect, useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import './style.css';
import { ItemProps, PaginationProps } from '../../../includes/types';
import { Thumbnail } from './sectionTwo';
import { PostData } from '../../../includes/function';
import Pagination from '../../../components/pagination';
import { ROUTES } from '../../../includes/constants';
export function SectionThree() {
  const [loading,setLoading] = useState<boolean>(false)
  const [page,setPage] = useState<number>(1);
  const [pageProps,setPageProps] = useState<PaginationProps>({})
  const [list,setList] = useState<ItemProps[]>(Array.from({length:9}).map((a,i)=>{
      return { }
  }));
  const [placeholder,setPlaceHolder] = useState<ItemProps[]>(Array.from({length:8}).map((a,i)=>{
    return { }
  }))
const GetLatestLands = (page:number)=>{
  PostData("recent_lands",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
if(res.status)
{
  setList(res.result);
  setPageProps({
    totalItem:res.totalItem,
    hasNextPage:res.hasNextPage,
    numberOfitems:res.numberOfitems
  });
}
  })
}

useEffect(()=>{
  GetLatestLands(1)
},[])
const navigate = useNavigate();
return (<section className="d-flex flex-column p-5 py-2 ">
<h3>Land</h3>
<div className='row' >
<div className='col-6' >
<p>Newest listed land ({list.filter((a,i)=>a.title).length})</p>
</div>
</div>
<div 
className='row'
>
{[...list.filter((item,i)=>i < 8),...placeholder.filter((a,i)=>i > list.filter((item,i)=>i < 8).length - 1)].map((item,i)=><Thumbnail
{...item}
key={i}
/>)}
</div>
{!loading && <Pagination  showMore={()=>{
  return navigate(String(ROUTES.Properties).replace(":type","land"))
}} />}
</section>)
}